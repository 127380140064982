import classNames from "classnames";
import React, { FC } from "react";
import { GiDna1, GiDna2 } from "react-icons/gi";
import RadioButton from "../../../shared/RadioButton";
import { AnalysisType } from "../../../types/types";
import autoMl from "../../../assets/img/ml_method_jadbio_small.png";
import deep from "../../../assets/img/ml_deep_logo_small.png";

type AnalysisTypeItemProps = {
  analysisType: AnalysisType;
  itemType: AnalysisType;
  header: string;
  description: string;
  clicked: () => void;
};

const AnalysisTypeItem: FC<AnalysisTypeItemProps> = ({
  analysisType,
  itemType,
  header,
  description,
  clicked,
}) => {
  const itemClasses = classNames({
    "analysis-option__container": true,
    active: analysisType === itemType,
  });

  return (
    <div className={itemClasses} onClick={clicked}>
      <RadioButton active={analysisType === itemType} />
      {itemType === "automl" ? (
        <div className="analysis-option__icon-container">
          <img className="analysis-option__icon" src={autoMl} />
        </div>
      ) : (
        <div className="analysis-option__icon-container">
          <img className="analysis-option__icon" src={deep} />
        </div>
      )}
      <div className="analysis-option__info">
        <p className="analysis-option__header">{header}</p>
        <p className="analysis-option__description">{description}</p>
      </div>
    </div>
  );
};

export default AnalysisTypeItem;
