import { FC, useState } from "react";
import { Button, ButtonToolbar } from "reactstrap";
import MutationField from "./MutationField";

type MutationsSubpageProps = {
  values: any;
  mutators: any;
};

const MutationsSubpage: FC<MutationsSubpageProps> = ({ values, mutators }) => {
  const [mutations, setMutations] = useState(1);

  const addMutation = () => {
    setMutations(mutations + 1);
  };

  return (
    <>
      {[...Array(mutations)].map((value: unknown, index: number) => (
        <MutationField
          key={index}
          mutationNumber={index + 1}
          mutators={mutators}
        />
      ))}
      <ButtonToolbar>
        <Button onClick={addMutation} color="primary" size="sm">
          + Add new genome variant
        </Button>
      </ButtonToolbar>
    </>
  );
};

export default MutationsSubpage;
