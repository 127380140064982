import React, { useEffect, useState } from "react";
import Typewriter from "typewriter-effect";
import { useNavigate } from "react-router-dom";

import WizardForm from "../../shared/form/WizardForm";
import WizardNavigation from "../../shared/form/WizardNavigation";
import GenomeSequence from "../Genome/components/GenomeSequence";
import GenomeAge from "./components/GenomeAge";
import { useAppDispatch, useAppSelector } from "../../App/hooks";
import {
  createSessionAsync,
  getQCResultsFileAsync,
  getMLResultsFileAsync,
  getAnnotatedVcfFileAsync,
  getMlResultsTableAsync,
  getQcControlledSequenceFileAsync,
  getFilteredMutationInfoTableAsync,
  getProteinBarPlotDataAsync,
  getSequenceInfoTableAsync,
  mutationCalculationAsync,
  runMlAsync,
  getPredictionHistogramDataAsync,
  getMutationHistogramPlotDataAsync,
  mutationCallStatusAsync,
} from "../../redux/asyncThunks/genomeThunks";
import { onMlFinishedToggle, selectLoading } from "../../redux/slices/genome";
import Loading from "../../shared/Loading";
import GenomeResults from "./components/GenomeResults";
import { cardToggle } from "../../redux/slices/layoutSlice";
import GenomeAnalysisType from "./components/GenomeAnalysisType";
import { RootState } from "../../redux/store";
import ErrorMessage from "../Mutation/components/ErrorMessage";
import GenomeCheck from "./components/GenomeCheck";

const Genome = () => {
  const [activePage, setActivePage] = useState(0);

  const navigate = useNavigate();

  const nextSubPage = (children: React.ReactChildren[]) => {
    setActivePage(Math.min(activePage + 1, children.length - 1));
  };
  const prevPage = () => {
    setActivePage(Math.max(activePage - 1, 0));
  };

  const loading = useAppSelector(selectLoading);
  const { analysisType, mlFinished, status } = useAppSelector(
    (state: RootState) => state.genome
  );
  const dispatch = useAppDispatch();

  const subpageTitle = () => {
    switch (activePage) {
      case 0:
        return <>Upload up to 100 SARS-CoV-2 sequences in FASTA format</>;
      case 1:
        return <>Check the sequence</>;
      case 2:
        return <>Set age for each sample (optional)</>;
      case 3:
        return <>Choose machine learning method type</>;
      case 4:
        return <></>;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(createSessionAsync());
  }, [dispatch]);

  // Checks if run ml call was fullfiled and calls rest api calls
  useEffect(() => {
    if (mlFinished) {
      // Graphs
      dispatch(getProteinBarPlotDataAsync());
      dispatch(getPredictionHistogramDataAsync());
      dispatch(getMutationHistogramPlotDataAsync());
      // Tables
      dispatch(getSequenceInfoTableAsync());
      dispatch(getFilteredMutationInfoTableAsync());
      // Files
      dispatch(getAnnotatedVcfFileAsync());
      dispatch(getQCResultsFileAsync());
      dispatch(getMLResultsFileAsync());
      dispatch(getQcControlledSequenceFileAsync());
      // Navigation
      navigate("/results");
      dispatch(cardToggle());
      dispatch(onMlFinishedToggle());
    }
  }, [mlFinished]);

  const handleSubmit = () => {
    dispatch(runMlAsync(analysisType));
    setActivePage(4);
  };
  return (
    <div className="modal-form__container">
      <WizardNavigation>
        <WizardNavigation.Item
          number={0}
          pageNumber={activePage}
          title="Sequence"
        />
        <WizardNavigation.Item
          number={1}
          pageNumber={activePage}
          title="Check"
        />
        <WizardNavigation.Item number={2} pageNumber={activePage} title="Age" />
        <WizardNavigation.Item
          number={3}
          pageNumber={activePage}
          title="ML model"
        />
        <WizardNavigation.Item
          number={4}
          pageNumber={activePage}
          title="Results"
        />
      </WizardNavigation>
      {loading === "loading" || (
        <div className="modal-form__subpage-title">{subpageTitle()}</div>
      )}
      <WizardForm
        onSubmit={handleSubmit}
        page={activePage}
        nextSubPage={nextSubPage}
        prevPage={prevPage}
      >
        <WizardForm.Page>
          {({ values, mutators }: any) => {
            return loading === "loading" ? (
              <Loading subText="Loading the application" />
            ) : (
              <GenomeSequence values={values} mutators={mutators} />
            );
          }}
        </WizardForm.Page>
        <WizardForm.Page>
          {({ values, mutators }: any) => {
            return loading === "loading" ? <Loading /> : <GenomeCheck />;
          }}
        </WizardForm.Page>
        <WizardForm.Page>
          {({ values, mutators }: any) => {
            return loading === "loading" ? <Loading /> : <GenomeAge />;
          }}
        </WizardForm.Page>
        <WizardForm.Page>
          {({ values, mutators }: any) => {
            return loading === "loading" ? <Loading /> : <GenomeAnalysisType />;
          }}
        </WizardForm.Page>
        <WizardForm.Page>
          {({ values, mutators }: any) => {
            return status === "failed" ? <ErrorMessage /> : <Loading />;
          }}
        </WizardForm.Page>
      </WizardForm>
    </div>
  );
};

export default Genome;
