import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAges, uploadAgeFile } from "../../api/age";

import {
  genomeSequenceFileUpload,
  genomeSequenceTextCheck,
  getSequenceInfoTable,
  mutationCalculation,
  runMl,
  getProteinBarPlotData,
  getFilteredMutationInfoTable,
  getMlResultsTable,
  getPredictionHistogramData,
  getMutationHistogramPlotData,
  getQcControlledSequenceFile,
  getQCResultsFile,
  getAnnotatedVcfFile,
  getAnnotatedMutationsFile,
  getMlResultsFile,
  mutationCallStatus,
} from "../../api/genome";
import { createSession, getSessionId } from "../../api/session";
import { AnalysisType } from "../../types/types";

export const createSessionAsync = createAsyncThunk(
  "genome/createSession",
  async () => {
    const response = await createSession();
    return response.data.session_id;
  }
);

export const getSessionIdAsync = createAsyncThunk(
  "genome/getSessionId",
  async () => {
    const response = await getSessionId();
    return response.data;
  }
);

export const checkGenomeSequenceTextAsync = createAsyncThunk(
  "genome/checkGenomeSequenceText",
  async (text: string) => {
    const response = await genomeSequenceTextCheck(text);
    return response.data;
  }
);

export const checkGenomeSequenceFileAsync = createAsyncThunk(
  "genome/checkGenomeSequenceFile",
  async (file: FormData) => {
    const response = await genomeSequenceFileUpload(file);
    return response.data;
  }
);

export const setAgesAsync = createAsyncThunk(
  "genome/setAges",
  async (ageList: string) => {
    const response = await setAges(ageList);
    return response.data;
  }
);

export const uploadAgeFileAsync = createAsyncThunk(
  "genome/uploadAgeFile",
  async (file: FormData) => {
    const response = await uploadAgeFile(file);
    return response.data;
  }
);

export const getSequenceInfoTableAsync = createAsyncThunk(
  "genome/getSequenceInfoTable",
  async () => {
    const response = await getSequenceInfoTable();
    return response.data;
  }
);

export const getFilteredMutationInfoTableAsync = createAsyncThunk(
  "genome/getFilteredMutaionInfoTable",
  async () => {
    const response = await getFilteredMutationInfoTable();
    return response.data;
  }
);

export const getMlResultsTableAsync = createAsyncThunk(
  "genome/getMlResultsTable",
  async () => {
    const response = await getMlResultsTable();
    return response.data;
  }
);

export const mutationCalculationAsync = createAsyncThunk(
  "genome/mutationCall",
  async () => {
    const response = await mutationCalculation();
    return response.data;
  }
);

export const runMlAsync = createAsyncThunk(
  "genome/runML",
  async (analysisType: AnalysisType) => {
    const response = await runMl(analysisType);
    return response.data;
  }
);

export const getProteinBarPlotDataAsync = createAsyncThunk(
  "genome/getProteinBarPlotData",
  async () => {
    const response = await getProteinBarPlotData();
    return response.data.records;
  }
);

export const downloadMutationPlotSvgUrlAsync = createAsyncThunk(
  "genome/downloadMutationPlotSvg",
  async () => {
    const response = await getProteinBarPlotData();
    const url = window.URL.createObjectURL(new Blob([response.data]));
    return url;
  }
);

export const getPredictionHistogramDataAsync = createAsyncThunk(
  "genome/getPredictionHistogramData",
  async () => {
    const response = await getPredictionHistogramData();
    return response.data.records;
  }
);

export const downloadMlResultsSummaryPlotUrlAsync = createAsyncThunk(
  "genome/downloadMlResultsSummaryPlotUrlAsync",
  async () => {
    const response = await getPredictionHistogramData();
    const url = window.URL.createObjectURL(new Blob([response.data]));
    return url;
  }
);

export const getMutationHistogramPlotDataAsync = createAsyncThunk(
  "genome/getMutationHistogramPlotData",
  async () => {
    const response = await getMutationHistogramPlotData();
    return response.data.records;
  }
);

export const downloadMutationStatPlotUrlAsync = createAsyncThunk(
  "genome/downloadMutationStatPlotUrlAsync",
  async () => {
    const response = await getMutationHistogramPlotData();
    const url = window.URL.createObjectURL(new Blob([response.data]));
    return url;
  }
);

export const getQcControlledSequenceFileAsync = createAsyncThunk(
  "genome/getQcControlledSequenceFile",
  async () => {
    const response = await getQcControlledSequenceFile();
    const url = window.URL.createObjectURL(new Blob([response.data]));
    return url;
  }
);

export const getQCResultsFileAsync = createAsyncThunk(
  "genome/getQCResultsFile",
  async () => {
    const response = await getQCResultsFile();
    const url = window.URL.createObjectURL(new Blob([response.data]));
    return url;
  }
);

export const getAnnotatedVcfFileAsync = createAsyncThunk(
  "genome/getAnnotatedVcfFile",
  async () => {
    const response = await getAnnotatedVcfFile();
    const url = window.URL.createObjectURL(new Blob([response.data]));
    return url;
  }
);

export const getAnnotatedMutationsFileAsync = createAsyncThunk(
  "genome/getAnnotatedMutationsFile",
  async () => {
    const response = await getAnnotatedMutationsFile();
    const url = window.URL.createObjectURL(new Blob([response.data]));
    return url;
  }
);

export const getMLResultsFileAsync = createAsyncThunk(
  "genome/getMlResultsFile",
  async () => {
    const response = await getMlResultsFile();
    const url = window.URL.createObjectURL(new Blob([response.data]));
    return url;
  }
);

export const mutationCallStatusAsync = createAsyncThunk(
  "genome/mutationCallStatus",
  async () => {
    const response = await mutationCallStatus();
    return response.data;
  }
);
