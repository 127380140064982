import api from "./api";

export const getMutationNameSuggestions = (text: string) => {
  return api.get("/get_mutation_name_suggestions", {
    params: {
      q: text,
    },
  });
};

export const createArtificialCovidGenomeData = (data: Array<Array<string>>) => {
  return api.post("/create_artificial_covid_genome_data", {
    genomes: data,
  });
};
