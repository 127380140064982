import React, { useEffect } from "react";
import classNames from "classnames";
import ReactDOM from "react-dom";
import { Modal } from "reactstrap";

type ModalProps = {
  isOpen: boolean;
  toggle: () => void;
  children: React.ReactNode;
  size?: "lg" | "xl";
};

const ModalContainer = ({ isOpen, toggle, children, size }: ModalProps) => {
  const modalContainerClass = classNames({
    modal__container: true,
    lg: size === "lg",
    xl: size === "xl",
  });

  useEffect(() => {
    isOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [isOpen]);

  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <Modal isOpen={isOpen} toggle={toggle} size={size}>
      <div className={modalContainerClass} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </Modal>,
    document.body
  );
};

export default ModalContainer;
