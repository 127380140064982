import { useMemo } from "react";
import { PaginationItem, PaginationLink } from "reactstrap";
import {
  Column,
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useBlockLayout,
} from "react-table";
import { IoFilter as Filter, IoDownload as Download } from "react-icons/io5";
import { MdRestore as Restore } from "react-icons/md";

interface DataType {
  id: string | number;
}

interface BasicTableProps {
  tableHeaders: Column<any>[];
  tableData: any[];
  title?: string;
  pagination?: boolean;
  exportFile?: boolean;
  saveButton?: boolean;
  headerColor?: string;
  header?: boolean;
  restore?: boolean;
  search?: boolean;
  detailedView?: boolean;
  qcCheckTable?: boolean;
  onExportHandler?: any;
  urlList?: {
    downloadUrl: string;
    downloadName: string;
  }[];
  isResizable?: boolean;
}

const BasicTable = ({
  tableHeaders,
  tableData,
  exportFile,
  search,
  saveButton,
  qcCheckTable,
  restore,
  headerColor,
  pagination,
  header,
  detailedView,
  title,
  urlList,
  onExportHandler,
  isResizable,
}: BasicTableProps) => {
  const columns = useMemo(() => tableHeaders, []);
  const data = useMemo(() => tableData, [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setPageSize,
    state,
    setGlobalFilter,
    resetResizing,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useResizeColumns,
    useBlockLayout
  );

  const { globalFilter } = state;
  const { pageIndex, pageSize } = state;

  const downloadLinks = () => {
    urlList?.forEach((link) => {
      // window.open(link)
      const downloadLink = document.createElement("a");
      downloadLink.href = link.downloadUrl;
      downloadLink.setAttribute("download", `${link.downloadName}`);
      downloadLink.click();
    });
  };

  const arrayPageIndex =
    pageIndex - 2 < 0
      ? pageOptions.slice(0, pageIndex + 3)
      : pageOptions.slice(pageIndex - 2, pageIndex + 3);

  return (
    <div className="table__container">
      {header && (
        <div className="table__header">
          <h4>{title}</h4>
          <div className="table__header--right">
            {/* {search && (
              <SearchInput filter={globalFilter} setFilter={setGlobalFilter} />
            )} */}

            {/* Save button */}
            {saveButton && (
              <div className="table__header-button">
                {/* <Save className="table__header-button--icon" /> */}
                <p>Save</p>
              </div>
            )}

            {/* Show columns */}
            {restore && (
              <div className="table__header-button" onClick={resetResizing}>
                <Restore className="table__header-button--icon" />
                <p>Restore</p>
              </div>
            )}

            {/* Export button */}
            {exportFile && (
              <div className="table__header-button" onClick={downloadLinks}>
                <Download className="table__header-button--icon" />
                <p>Export</p>
              </div>
            )}

            {/* Detailed View */}
            {detailedView && (
              <div className="table__header-button">
                {/* <OpenInNewIcon className="table__header-button--icon" /> */}
                <p>Pop Out</p>
              </div>
            )}
          </div>
        </div>
      )}
      <div className={`table ${qcCheckTable && "qc-check"}`}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <Filter className="th-badge" />
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? "isResizing" : ""
                      }`}
                    />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={
                    Object.values(row.original).includes("Failed")
                      ? "qc-check-row-fail"
                      : "qc-check-row-success"
                  }
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {pagination && (
        <div className="table__footer">
          <div className="table__footer-count">
            Page {pageIndex + 1} of {pageOptions.length}
          </div>
          <div className="table__footer-pages">
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>
            {arrayPageIndex.map((i) => (
              <PaginationItem
                active={pageIndex === i}
                key={i}
                className="pagination__item"
              >
                <PaginationLink
                  key={i}
                  type="button"
                  onClick={() => gotoPage(i)}
                  className="pagination__link"
                >
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>
          </div>
          <div className="table__footer-results">
            <span>Results on page</span>
            <select
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </div>
  );
};

export default BasicTable;
