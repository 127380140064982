import React from "react";
import { useNavigate } from "react-router-dom";
import itkLogo from "../../assets/img/ITK_logo_color_transparent.png";
import SELogo from "../../assets/img/semmelweis.png";

const Footer = () => {
  const navigate = useNavigate();

  const routeChange = (address: string) => {
    window.location.href = address;
  };

  return (
    <div className="footer">
      <div
        className="logo__container"
        onClick={() => routeChange("https://pongor2.itk.ppke.hu/")}
      >
        <img src={itkLogo} alt="ITK logo" />
      </div>
      <div className="cite-request">
        <p>Please kindly cite our papers to support further development:</p>
        <p>
          <a href="https://doi.org/10.1101/2022.07.01.496571">
            CovidOutcome2: a tool for SARS-CoV2 mutation identification
            and for disease severity prediction.
          </a>{" "}
          <span> Kalcsevszki R. et al, </span> bioRxiv 2022.07.01.496571
        </p>
        <p>
          <a href="https://academic.oup.com/database/article-abstract/doi/10.1093/database/baab020/6272506">
            COVIDOUTCOME—estimating COVID severity based on mutation signatures
            in the SARS-CoV-2 genome.
          </a>{" "}
          <span>Nagy A. et al,</span> Database (2021)
        </p>
        <p> © 2022 PPCU Bioinformatics Research Group</p>
        <p>
          Developed by{" "}
          <a href="https://peruzziservices.com">Peruzzi Services</a>
        </p>
      </div>

      <div
        className="logo__container"
        onClick={() => routeChange("https://gyorffy.semmelweis.hu/")}
      >
        <img src={SELogo} alt="Semmelweis logo" />
      </div>
    </div>
  );
};

export default Footer;
