import classNames from "classnames";
import React, { FC } from "react";

interface RadioButtonProps {
  active: boolean;
}

// Not a real form button, don't use with form

const RadioButton: FC<RadioButtonProps> = ({ active }) => {
  const outerClass = classNames({
    "radio-button__outer": true,
    active: active,
  });

  return (
    <div className={outerClass}>
      <div className={"radio-button__inner"} />
    </div>
  );
};

export default RadioButton;
