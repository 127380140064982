import React, { Children, cloneElement, ReactElement } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  sampleSequencePopulate,
  sampleMutationPopulate,
} from "../../utilities/formMutators";

import Button from "../Button";

type WizardFormProps = {
  children: any;
  onSubmit: (values: any) => void;
  initialValue?: any;
  nextSubPage: (children: React.ReactChildren[], values: any) => void;
  prevPage: (children: React.ReactChildren[]) => void;
  page: any;
};

const WizardForm = ({
  children,
  onSubmit,
  initialValue,
  nextSubPage,
  prevPage,
  page,
}: WizardFormProps) => {
  const activePage = Children.toArray(children)[page];
  const isLastPage = page === Children.count(children) - 2;

  const { isSequenceTextareaFailed } = useSelector(
    (state: RootState) => state.genome
  );

  const handleSubmit = (values: any) => {
    if (isLastPage) {
      onSubmit(values);
    } else {
      nextSubPage(children, values);
    }
  };

  const cardChosen = useSelector(
    (state: RootState) => state.layout.cardCollapsed
  );

  // Submit button for each subpage
  let submitButton: JSX.Element;
  switch (page) {
    case 0:
      submitButton = (
        <Button
          type="submit"
          disabled={isSequenceTextareaFailed}
          title={isLastPage ? "Submit" : "Next"}
          style={{ marginLeft: "auto" }}
        />
      );
      break;
    case 1:
      submitButton = (
        <Button
          type="submit"
          // disabled={wizardStage !== 1}
          title={isLastPage ? "Submit" : "Next"}
          style={{ marginLeft: "auto" }}
        />
      );
      break;
    case 2:
      submitButton = (
        <Button
          type="submit"
          // disabled={wizardStage !== 1}
          title={isLastPage ? "Submit" : "Next"}
          style={{ marginLeft: "auto" }}
        />
      );
      break;
    case 3:
      submitButton =
        cardChosen === "genome" ? (
          <Button
            type="submit"
            // disabled={wizardStage !== 1}
            title={isLastPage ? "Submit" : "Next"}
            style={{ marginLeft: "auto" }}
          />
        ) : (
          <></>
        );
      break;
    case 4:
      submitButton = <></>;
      break;

    default:
      break;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      mutators={{ sampleSequencePopulate, sampleMutationPopulate }}
      render={({ handleSubmit, values, form: { mutators } }) => (
        <form className="form" onSubmit={handleSubmit}>
          {cloneElement(activePage as ReactElement<any>, {
            values: values,
            mutators: mutators,
          })}
          <div className="btn__toolbar">
            {page !== 0 ? (
              <Button
                type="button"
                title="Back"
                onClick={() => prevPage(children)}
              />
            ) : (
              ""
            )}
            {submitButton}
          </div>
        </form>
      )}
    />
  );
};

export default WizardForm;

WizardForm.Page = function Page({ children, values, mutators }: any) {
  const props = {
    values,
    mutators,
  };
  return children(props);
};
