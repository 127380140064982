import { FC } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  XAxis,
  YAxis,
} from "recharts";
import { PredictionHistogramData } from "../../../types/interfaces";
import RenderLegend from "./Legend";

interface PredictionHistogramProps {
  height: number;
  width?: number;
  data: PredictionHistogramData[];
}

const PredictionHistogram: FC<PredictionHistogramProps> = ({
  height,
  width,
  data,
}) => {
  const formattedXAxes = (value: any, index: number) => {
    return `${Math.floor(Number(value) * 10) / 10}`;
  };

  return (
    <BarChart
      height={height}
      width={width}
      data={data}
      margin={{
        right: 100,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="predictionScore"
        tickFormatter={(value, index) => formattedXAxes(value, index)}
      />
      <YAxis dataKey="occurrences" interval={0} tickCount={11} />
      <Bar dataKey="occurrences" barSize={5}>
        {data?.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Bar>
      <Legend
        verticalAlign="middle"
        content={<RenderLegend data={data} reversed />}
      />
    </BarChart>
  );
};

export default PredictionHistogram;
