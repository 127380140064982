import React, { useEffect, useState } from "react";
import classNames from "classnames";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";

import Results1 from "../../assets/img/results1.png";
import Results2 from "../../assets/img/results2.png";
import ResultsTable from "./ResultsTable";
import ResultsTableau from "./ResultsTableau";
import { useAppSelector } from "../../App/hooks";
import { RootState } from "../../redux/store";
import { mutationCalculationAsync } from "../../redux/asyncThunks/genomeThunks";
import ResultGraphs from "./ResultGraphs";
import PredictionHistogram from "../../shared/charts/PredictionHistogram";
import MutationsByProtein from "../../shared/charts/MutationsByProtein";
import TableTabs from "./TableTabs";
import GraphTabs from "./GraphTabs";
import { selectLoading } from "../../redux/slices/genome";
import Loading from "../../shared/Loading";

const ResultsTabs = () => {
  const [activeTab, setActiveTab] = useState("1");

  const activeTabToggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { predictionHistogramData, proteinBarPlotData } = useAppSelector(
    (state: RootState) => state.genome
  );

  const { cardCollapsed } = useAppSelector((state: RootState) => state.layout);

  const loading = useAppSelector(selectLoading);

  const mutationsurl = useAppSelector(
    (state: RootState) => state.genome.annotatedMutationsFileUrl
  );

  const vcfurl = useAppSelector(
    (state: RootState) => state.genome.annotatedVcfFileUrl
  );

  const sequrl = useAppSelector(
    (state: RootState) => state.genome.qcControlledSequenceFileUrl
  );

  const qcresultsurl = useAppSelector(
    (state: RootState) => state.genome.qcResultsFileUrl
  );

  const mlresultsurl = useAppSelector(
    (state: RootState) => state.genome.mlResultsFileUrl
  );

  useEffect(() => {
    console.log(loading);
  }, [loading]);

  return (
    <div className="page__container">
      <Card>
        <CardBody>
          <h4>Figures</h4>
          <div style={{ display: "flex" }}>
            <GraphTabs />
          </div>
          <h4 style={{ margin: "30px 0 20px" }}>Table results</h4>
          <TableTabs />
          <h4 style={{ margin: "30px 0 20px" }}>Downloads</h4>
          <div>
            {cardCollapsed === "genome" && (
                <Button className="btn__yellow btn__with_margin" href={sequrl} download="qc_sequences.fasta">
                  QC Sequences
                </Button>
            )}
            {cardCollapsed === "genome" && (
              <Button className="btn__yellow btn__with_margin" href={qcresultsurl} download="qc_results.tsv">
                QC Results
              </Button>
            )}
            {cardCollapsed === "genome" && (
            <Button className="btn__yellow btn__with_margin" href={vcfurl} download="annotations.vcf">
              Mutations (.vcf)
            </Button>
            )}
            <Button className="btn__yellow btn__with_margin" href={mutationsurl} download="mutations.tsv">
              Mutations (.tsv)
            </Button>
            <Button className="btn__yellow btn__with_margin" href={mlresultsurl} download="ml_results.tsv">
              Predictions
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ResultsTabs;
