import { useEffect } from "react";
import classNames from "classnames";
import ReactDOM from "react-dom";
import { useAppDispatch, useAppSelector } from "../App/hooks";
import { menuToggle, selectMenuState } from "../redux/slices/layoutSlice";
import { CgClose } from "react-icons/cg";

const ContactsPage = () => {
  const isOpen = useAppSelector(selectMenuState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    isOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [isOpen]);

  const modalClass = classNames({
    menuModal: true,
    collapsed: isOpen,
  });

  return ReactDOM.createPortal(
    <div className={modalClass}>
      <div className="modal__close">
        <CgClose
          className="modal__close-icon"
          onClick={() => dispatch(menuToggle())}
        />
      </div>
      <div className="modal__title">Contacts</div>

      <div className="modal__wrapper">
        <div className="contacts__container">
          <div className="contacts__left">
            <h3>Get in touch</h3>
            <h5>
              Do you have questions about our research? Get in touch with us!
            </h5>
          </div>
          <div className="contacts__right">
            <div className="contacts__card">
              <div className="contacts__card-row">
                <h5>PPCU Bioinformatics</h5>
                <p>
                  Pázmány Péter Catholic University Jedlik Laboratories:
                  Bioinformatics
                </p>
              </div>
              <div className="contacts__card-row">
                <h5>Research group</h5>
                <p>
                  <a href="#">https://pongor2.ftk.ppke.hu</a>
                </p>
              </div>
              <div className="contacts__card-row">
                <h5>Email</h5>
                <p style={{ marginBottom: "0" }}>pongor.sandor@ftk.ppke.hu</p>
                <p style={{ marginBottom: "0" }}>
                  kalcsevszki.regina@itk.ppke.hu
                </p>
                <p style={{ marginBottom: "0" }}>ligeti.balazs@itk.ppke.hu</p>
              </div>
              <div className="contacts__card-row">
                <h5>Telephone</h5>
                <p>+36-30-330-8038</p>
              </div>
              <div className="contacts__card-row">
                <h5>Address</h5>
                <p>50/A Práter street, Budapest, Hungary 1083</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default ContactsPage;
