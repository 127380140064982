import classNames from "classnames";

type HeaderType = {
  children: React.ReactNode;
  pageName: string;
};

const Header = ({ children, pageName }: HeaderType) => {
  return <div className={`header ${pageName}`}>{children}</div>;
};

export default Header;

Header.Title = function Title({ children }: any) {
  return <div className="header__title">{children}</div>;
};

type HeaderContentType = {
  contentText: string;
};

Header.Content = function Content({ contentText }: HeaderContentType) {
  return (
    <div className="header__content">
      <h1>{contentText}</h1>
    </div>
  );
};

type HeaderButtonType = {
  buttonText: string;
  small?: boolean;
  onClickFunc: () => void;
};

Header.Button = function Button({
  buttonText,
  small,
  onClickFunc,
}: HeaderButtonType) {
  const buttonClasses = classNames({
    btn: true,
    btn__yellow: true,
    sm: small,
  });

  return (
    <button type="button" className={buttonClasses} onClick={onClickFunc}>
      {buttonText}
    </button>
  );
};
