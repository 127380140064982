import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { showNotification } from "../../shared/Notification";

import {
  checkGenomeSequenceFileAsync,
  checkGenomeSequenceTextAsync,
  createSessionAsync,
  downloadMlResultsSummaryPlotUrlAsync,
  downloadMutationPlotSvgUrlAsync,
  downloadMutationStatPlotUrlAsync,
  getAnnotatedMutationsFileAsync,
  getAnnotatedVcfFileAsync,
  getFilteredMutationInfoTableAsync,
  getPredictionHistogramDataAsync,
  getProteinBarPlotDataAsync,
  getMutationHistogramPlotDataAsync,
  getQcControlledSequenceFileAsync,
  getQCResultsFileAsync,
  getSequenceInfoTableAsync,
  getSessionIdAsync,
  getMLResultsFileAsync,
  mutationCalculationAsync,
  runMlAsync,
  setAgesAsync,
  uploadAgeFileAsync,
} from "../asyncThunks/genomeThunks";
import {
  IMlTable,
  IMutationTable,
  ISequenceInfoTable,
  MutationsByProteinData,
} from "../../types/interfaces";
import { AnalysisType } from "../../types/types";
import { randomColor } from "../../utilities";

export interface GenomeState {
  status: "idle" | "loading" | "failed";
  sessionId: string;
  stepComplete: boolean;
  isSequenceTextareaFailed: boolean;
  proteinBarPlotData: MutationsByProteinData[];
  predictionHistogramData: any;
  mutationHistrogramPlotData: any;
  sequenceTableData: ISequenceInfoTable[] | null;
  filteredMutationTableData: IMutationTable[] | null;
  mlFinished: boolean;
  mlResultData: IMlTable[] | null;
  qcControlledSequenceFileUrl: string;
  qcResultsFileUrl: string;
  annotatedVcfFileUrl: string;
  annotatedMutationsFileUrl: string;
  mlResultsFileUrl: string;
  proteinBarPlotDataUrl: string;
  predictionHistogramDataUrl: string;
  mutationHistrogramPlotDataUrl: string;
  wizardStage: 0 | 1 | 2 | 3;
  analysisType: AnalysisType;
  qcCheckSequenceData: string;
}

const initialState: GenomeState = {
  status: "idle",
  sessionId: "",
  stepComplete: false,
  isSequenceTextareaFailed: true,
  proteinBarPlotData: [],
  predictionHistogramData: null,
  mutationHistrogramPlotData: null,
  sequenceTableData: null,
  filteredMutationTableData: null,
  mlFinished: false,
  mlResultData: null,
  qcControlledSequenceFileUrl: "",
  qcResultsFileUrl: "",
  annotatedVcfFileUrl: "",
  annotatedMutationsFileUrl: "",
  proteinBarPlotDataUrl: "",
  mlResultsFileUrl: "",
  predictionHistogramDataUrl: "",
  mutationHistrogramPlotDataUrl: "",
  wizardStage: 0,
  analysisType: "automl",
  qcCheckSequenceData: "",
};

export const genomeSlice = createSlice({
  name: "genome",
  initialState,
  reducers: {
    changeAnalysisType: (state, action: PayloadAction<AnalysisType>) => {
      state.analysisType = action.payload;
    },
    onMlFinishedToggle: (state) => {
      state.mlFinished = !state.mlFinished;
    },
    onIsSequenceTextareaFailedChange: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isSequenceTextareaFailed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      // Create Session
      .addCase(createSessionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createSessionAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.sessionId = action.payload;
      })
      .addCase(createSessionAsync.rejected, (state) => {
        state.status = "failed";
        showNotification({
          type: "danger",
          message: "Could not start the session",
        });
      })

      // Get Session Id
      .addCase(getSessionIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSessionIdAsync.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(getSessionIdAsync.rejected, (state) => {
        state.status = "failed";
        showNotification({
          type: "danger",
          message: "Could not get the session id",
        });
      })

      // Check Genome Sequence Textarea
      .addCase(checkGenomeSequenceTextAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.isSequenceTextareaFailed = false;
        state.qcCheckSequenceData = action.payload.sequence_profile;
      })
      .addCase(checkGenomeSequenceTextAsync.rejected, (state) => {
        state.status = "failed";
        state.isSequenceTextareaFailed = true;
      })

      // Check Genome Sequence File
      .addCase(checkGenomeSequenceFileAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.wizardStage = 1;
        state.qcCheckSequenceData = action.payload.sequence_profile;
      })
      .addCase(checkGenomeSequenceFileAsync.rejected, (state) => {
        state.status = "failed";
        showNotification({
          type: "danger",
          message: "Could not check the genome sequence file",
        });
      })

      // Check Genome Age Textarea
      .addCase(setAgesAsync.fulfilled, (state) => {
        state.status = "idle";
        state.wizardStage = 2;
      })
      .addCase(setAgesAsync.rejected, (state) => {
        state.status = "failed";
        showNotification({
          type: "danger",
          message: "Could not check the genome age textfield",
        });
      })

      // Check Genome Age File
      .addCase(uploadAgeFileAsync.fulfilled, (state) => {
        state.status = "idle";
        state.wizardStage = 2;
      })
      .addCase(uploadAgeFileAsync.rejected, (state) => {
        state.status = "failed";
        showNotification({
          type: "danger",
          message: "Could not check the age sequence file",
        });
      })

      // Mutation Call
      .addCase(mutationCalculationAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(mutationCalculationAsync.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(mutationCalculationAsync.rejected, (state) => {
        state.status = "failed";
        showNotification({
          type: "danger",
          message: "Could not calculate mutation",
        });
      })

      // Get Sequence Information Table
      .addCase(getSequenceInfoTableAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSequenceInfoTableAsync.fulfilled, (state, action) => {
        state.status = "idle";
        const result = action.payload;
        state.sequenceTableData = JSON.parse(result.sequence_info);
      })
      .addCase(getSequenceInfoTableAsync.rejected, (state) => {
        state.status = "failed";
        showNotification({
          type: "danger",
          message: "Could not get Sequence information table",
        });
      })

      // Get Mutation Information Table
      // .addCase(getFilteredMutationInfoTableAsync.pending, (state) => {
      //   state.status = "loading";
      // })
      .addCase(getFilteredMutationInfoTableAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.filteredMutationTableData = JSON.parse(action.payload);
      })
      .addCase(getFilteredMutationInfoTableAsync.rejected, (state) => {
        state.status = "failed";
        showNotification({
          type: "danger",
          message: "Could not get Mutation information table",
        });
      })

      // Run Ml
      .addCase(runMlAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(runMlAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.mlResultData = JSON.parse(action.payload);
        state.mlFinished = true;
      })
      .addCase(runMlAsync.rejected, (state) => {
        state.status = "failed";
        showNotification({
          type: "danger",
          message: "Could not get Ml information table",
        });
      })

      // Get Mutation Plot SVG
      .addCase(getProteinBarPlotDataAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProteinBarPlotDataAsync.fulfilled, (state, action) => {
        state.status = "idle";
        const mData = action.payload.map((element: any) => {
          return {
            ...element,
            color: randomColor(),
          };
        });
        state.proteinBarPlotData = mData;
      })
      .addCase(getProteinBarPlotDataAsync.rejected, (state) => {
        state.status = "failed";
        showNotification({
          type: "danger",
          message: "Could not get Mutation Plot graph",
        });
      })

      // Get Ml Results Summary Plot
      .addCase(getPredictionHistogramDataAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPredictionHistogramDataAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.predictionHistogramData = action.payload;
      })
      .addCase(getPredictionHistogramDataAsync.rejected, (state) => {
        state.status = "failed";
        showNotification({
          type: "danger",
          message: "Could not get Ml Results Summary Plot",
        });
      })

      // Get Mutation Stat Plot
      .addCase(getMutationHistogramPlotDataAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMutationHistogramPlotDataAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.mutationHistrogramPlotData = action.payload;
      })
      .addCase(getMutationHistogramPlotDataAsync.rejected, (state) => {
        state.status = "failed";
        showNotification({
          type: "danger",
          message: "Could not get Mutation Stat Plot",
        });
      })

      // Get QC Controlled Sequence File
      .addCase(getQcControlledSequenceFileAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getQcControlledSequenceFileAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.qcControlledSequenceFileUrl = action.payload;
      })
      .addCase(getQcControlledSequenceFileAsync.rejected, (state) => {
        state.status = "failed";
        showNotification({
          type: "danger",
          message: "Could not get Mutation Stat Plot",
        });
      })

      // Get QC Results File
      .addCase(getQCResultsFileAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getQCResultsFileAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.qcResultsFileUrl = action.payload;
      })
      .addCase(getQCResultsFileAsync.rejected, (state) => {
        state.status = "failed";
        showNotification({
          type: "danger",
          message: "Could not get QC Results File",
        });
      })

      // Get Annotated VCF File
      .addCase(getAnnotatedVcfFileAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAnnotatedVcfFileAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.annotatedVcfFileUrl = action.payload;
      })
      .addCase(getAnnotatedVcfFileAsync.rejected, (state) => {
        state.status = "failed";
        showNotification({
          type: "danger",
          message: "Could not get Mutation Stat Plot",
        });
      })

      // Get Annotated Mutations File
      .addCase(getAnnotatedMutationsFileAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAnnotatedMutationsFileAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.annotatedMutationsFileUrl = action.payload;
      })
      .addCase(getAnnotatedMutationsFileAsync.rejected, (state) => {
        state.status = "failed";
        showNotification({
          type: "danger",
          message: "Could not get Mutation Stat Plot",
        });
      })

      // Get ML Results File
      .addCase(getMLResultsFileAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMLResultsFileAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.mlResultsFileUrl = action.payload;
      })
      .addCase(getMLResultsFileAsync.rejected, (state) => {
        state.status = "failed";
        showNotification({
          type: "danger",
          message: "Could not get ML Results File",
        });
      })

      // Download Mutation Plot SVG
      .addCase(downloadMutationPlotSvgUrlAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(downloadMutationPlotSvgUrlAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.proteinBarPlotDataUrl = action.payload;
      })
      .addCase(downloadMutationPlotSvgUrlAsync.rejected, (state) => {
        state.status = "failed";
        showNotification({
          type: "danger",
          message: "Could not create mutation plot svg file",
        });
      })

      // Download Ml Results Summary Plot URL
      .addCase(downloadMlResultsSummaryPlotUrlAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        downloadMlResultsSummaryPlotUrlAsync.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.predictionHistogramDataUrl = action.payload;
        }
      )
      .addCase(downloadMlResultsSummaryPlotUrlAsync.rejected, (state) => {
        state.status = "failed";
        showNotification({
          type: "danger",
          message: "Could not Ml results summary plot url",
        });
      })

      // Download Mutation Stat Plot URL
      .addCase(downloadMutationStatPlotUrlAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(downloadMutationStatPlotUrlAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.mutationHistrogramPlotDataUrl = action.payload;
      })
      .addCase(downloadMutationStatPlotUrlAsync.rejected, (state) => {
        state.status = "failed";
        showNotification({
          type: "danger",
          message: "Could not create mutation stat plot url",
        });
      });
  },
});

export const {
  changeAnalysisType,
  onMlFinishedToggle,
  onIsSequenceTextareaFailedChange,
} = genomeSlice.actions;

export const selectLoading = (state: RootState) => state.genome.status;
export const selectfilteredMutationTableData = (state: RootState) =>
  state.genome.status;

export default genomeSlice.reducer;
