import React, { FC, useEffect, useState } from "react";
import RenderLegend from "./Legend";
import RenderTooltip from "./Tooltip";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { MutationsByPositionsData } from "../../../types/interfaces";
import { testData } from "../../../assets/files/test";
import { groupByProteinName } from "../../../utilities";

const barColors = [
  "#f77b72",
  "#7885cb",
  "#4cb5ab",
  "#ffd351",
  "#ff8964",
  "#ba67c8",
  "#4ec3f7",
  "#aed580",
  "#ffb74c",
  "#f06191",
  "#63b5f7",
];

interface MutationsByProteinProps {
  height: number;
  width: number;
  reversed?: boolean;
  data: MutationsByPositionsData[];
}

const MutationsByPositions: FC<MutationsByProteinProps> = ({
  height,
  width,
  reversed,
  data,
}) => {
  const [proteinList, setProteinList] = useState<string[]>([]);
  const [modifiedData, setModifiedData] = useState<any[]>([]);

  let newList: string[] = [];
  useEffect(() => {
    data
      ?.filter((value, index, self) => {
        return (
          self.findIndex((v) => v.proteinName === value.proteinName) === index
        );
      })
      .map((ele) => {
        newList.push(ele.proteinName);
        setProteinList(newList);
      });
  }, []);

  useEffect(() => {
    setModifiedData(groupByProteinName(testData, "proteinName"));
    groupByProteinName(testData, "proteinName").map((entry) =>
      console.log(entry.data)
    );
  }, []);

  return (
    <AreaChart
      height={height}
      width={width}
      data={data}
      margin={reversed ? { right: 100 } : { left: 50 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="startPos" domain={[0, 30000]} />
      <YAxis dataKey="occurrences" />
      <Area dataKey="occurrences" />
      <Legend
        content={<RenderLegend data={proteinList} reversed={reversed} />}
        verticalAlign="middle"
      />
      <Tooltip content={RenderTooltip} />
    </AreaChart>
  );
};

export default MutationsByPositions;
