import { FC } from "react";
import { Field } from "react-final-form";
import { useAppSelector } from "../../../App/hooks";
import { RootState } from "../../../redux/store";
import RenderMultiSelectField from "../../../shared/form/MultiSelect";

type MutationFieldProps = {
  mutationNumber: number;
  mutators: any;
};

const MutationField: FC<MutationFieldProps> = ({
  mutationNumber,
  mutators,
}) => {
  const { sampleMutationPopulate } = mutators;
  const optionsAsync = useAppSelector(
    (state: RootState) => state.mutations.mutationNameSuggestions
  );
  return (
    <div className="form__form-group">
      <p className="form__form-group-label">
        Genome variant {mutationNumber} (use a{" "}
        <span onClick={sampleMutationPopulate}>template for Omicron</span>)
      </p>
      <div className="form__form-group-field">
        <Field
          name={`mutation${mutationNumber}`}
          render={({ input, meta }) => {
            return (
              <RenderMultiSelectField
                input={input}
                meta={meta}
                options={optionsAsync}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default MutationField;
