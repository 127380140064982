import React, { FC, useEffect } from "react";
import { Column } from "react-table";
import ReactTooltip from "react-tooltip";
import { useAppDispatch, useAppSelector } from "../../App/hooks";
import { getAnnotatedVcfFileAsync } from "../../redux/asyncThunks/genomeThunks";
import { RootState } from "../../redux/store";
import BasicTable from "../../shared/table/BasicTable";
import { IMlTable } from "../../types/interfaces";

interface MlTableProps {
  data: IMlTable[];
}

const MlTable: FC<MlTableProps> = ({ data }) => {
  const url = useAppSelector(
    (state: RootState) => state.genome.mlResultsFileUrl
  );
  const dispatch = useAppDispatch();

  const headers: Column<IMlTable>[] = [
    {
      Header: "User ID",
      accessor: "user_seq_id",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "Age",
      accessor: "age",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "Mild Prediction Score",
      accessor: "prediction_score_mild",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "Prediction Score",
      accessor: "prediction_score",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "Applied Model Type",
      accessor: "applied_model_type",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "Prediction Confidence",
      accessor: "prediction_confidence",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "Label",
      accessor: "Label",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
  ];

  return (
    <>
      <ReactTooltip
        place="left"
        type="success"
        effect="solid"
        multiline={true}
        className="table__tooltip"
      />
      <BasicTable
        tableHeaders={headers}
        tableData={data}
        pagination
        header
        title="Machine Learning"
        exportFile
        restore
        urlList={[{ downloadUrl: url, downloadName: "ml_results.tsv" }]}
      />
    </>
  );
};

export default MlTable;
