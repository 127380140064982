import { AnalysisType } from "../types/types";
import api from "./api";

export const genomeSequenceTextCheck = (text: string) => {
  return api.post("/upload_and_qc_check_char_sequence_data", {
    sequences: text,
  });
};

export const genomeSequenceFileUpload = (file: FormData) => {
  return api.post("/upload_and_qc_check_file_sequence_data", file);
};

export const mutationCalculation = () => {
  return api.post("/mutation_call");
};

export const getSequenceInfoTable = () => {
  return api.get("/get_sequence_information_table");
};

export const getFilteredMutationInfoTable = () => {
  return api.get("/get_filtered_mutation_information_table");
};

export const getMlResultsTable = () => {
  return api.get("/get_ml_results_table");
};

export const runMl = (analysisType: AnalysisType) => {
  return api.post("/run_ml", { machine_learning_methods_type: analysisType });
};

export const getProteinBarPlotData = () => {
  return api.get("/get_protein_bar_plot_data");
};

export const getPredictionHistogramData = () => {
  return api.get("/get_prediction_histogram_data");
};

export const getMutationHistogramPlotData = () => {
  return api.get("/get_mutation_histogram_plot_data");
};

export const getQcControlledSequenceFile = () => {
  return api.get("/get_qc_controlled_sequence_file", {
    headers: {
      "Content-Type": "application/octet-stream",
      "Content-Disposition": "attachment",
    },
  });
};

export const getQCResultsFile = () => {
  return api.get("/get_qc_results_file", {
    headers: {
      "Content-Type": "application/octet-stream",
      "Content-Disposition": "attachment",
    },
  });
};

export const getAnnotatedVcfFile = () => {
  return api.get("/get_annotated_vcf_file", {
    headers: {
      "Content-Type": "application/octet-stream",
      "Content-Disposition": "attachment",
    },
  });
};
export const getAnnotatedMutationsFile = () => {
  return api.get("/get_annotated_mutations_file", {
    headers: {
      "Content-Type": "application/octet-stream",
      "Content-Disposition": "attachment",
    },
  });
};

export const getMlResultsFile = () => {
  return api.get("/get_ml_results_file", {
    headers: {
      "Content-Type": "application/octet-stream",
      "Content-Disposition": "attachment",
    },
  });
};

export const mutationCallStatus = () => {
  return api.get("/mutation_call_status");
};
