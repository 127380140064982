import React from "react";
import Notification from "rc-notification";

let notification: any = null;
Notification.newInstance({ style: { top: 0 } }, (n) => (notification = n));

type showNotificationProps = {
  message: string;
  type: "primary" | "success" | "warning" | "danger";
};

const showNotification = ({ message, type }: showNotificationProps) => {
  const notificationInitialProps = {
    content: <FullWidthNotification type={type} message={message} />,
    duration: 3,
    closable: true,
  };
  notification.notice(notificationInitialProps);
};

type fullWidthNoticiationProps = {
  type: string;
  message?: string;
};

const FullWidthNotification = ({
  type,
  message,
}: fullWidthNoticiationProps) => (
  <div className={`notification notification--full-wide notification--${type}`}>
    <p className="notification__message">{message}</p>
  </div>
);

export { showNotification };
