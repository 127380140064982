import { Outlet } from "react-router-dom";
import Footer from "../../layout/Footer";

import Sidebar from "../../layout/Sidebar";

const WrappedRoutes = () => (
  <>
    <Sidebar />
    <div className="container__wrap">
      <Outlet />
      <Footer />
    </div>
  </>
);

export default WrappedRoutes;
