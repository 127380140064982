import axios from "axios";

let headers = {
  "Content-Type": "application/json",
};

// //deployment
// const baseUrl = "https://covidapi.bio-ml.com/";
// //dev
// const baseUrl = "https://bio-apps.itk.ppke.hu/covidoutcome/"; //DEV

const baseUrl =
  process.env.BASE_URL === undefined
    ? "https://covidapi.bio-ml.com/"
    : process.env.BASE_URL;

const api = axios.create({
  baseURL: baseUrl,
  headers,
  withCredentials: true,
});

export const tableauApi = axios.create({
  baseURL: "https://eu-west-1a.online.tableau.com",
  headers,
});

export default api;
