import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface LayoutState {
  isMenuOpen: boolean;
  cardCollapsed: "genome" | "mutation";
  isCardOpen: boolean;
}

const initialState: LayoutState = {
  isMenuOpen: false,
  cardCollapsed: "genome",
  isCardOpen: false,
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    menuToggle: (state) => {
      state.isMenuOpen = !state.isMenuOpen;
    },
    cardTypeChange: (state, action: PayloadAction<"genome" | "mutation">) => {
      state.cardCollapsed = action.payload;
    },
    cardToggle: (state) => {
      state.isCardOpen = !state.isCardOpen;
    },
  },
});

export const { menuToggle, cardTypeChange, cardToggle } = layoutSlice.actions;

export const selectMenuState = (state: RootState) => state.layout.isMenuOpen;
export const selectCardType = (state: RootState) => state.layout.cardCollapsed;
export const selectCardState = (state: RootState) => state.layout.isCardOpen;

export default layoutSlice.reducer;
