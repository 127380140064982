import { FC } from "react";
import classNames from "classnames";
import { LegendProps } from "recharts";
import { randomColor } from "../../../utilities";

interface RenderLegendProps extends LegendProps {
  reversed?: boolean;
  data: string[];
}

const barColors = [
  "#f77b72",
  "#7885cb",
  "#4cb5ab",
  "#ffd351",
  "#ff8964",
  "#ba67c8",
  "#4ec3f7",
  "#aed580",
  "#ffb74c",
  "#f06191",
  "#63b5f7",
];

const Legend: FC<RenderLegendProps> = ({ reversed, data }) => {
  const legendClass = classNames({
    protein__legend: true,
    reversed: reversed,
  });

  return (
    <ul className={legendClass}>
      {data.map((entry, index) => (
        <li key={`item-${index}`}>
          <span
            className="legend__box"
            style={{
              background: barColors[index % 20],
            }}
          />
          {entry}
        </li>
      ))}
    </ul>
  );
};

export default Legend;
