import classNames from "classnames";
import React, { FC } from "react";
import { LegendProps } from "recharts";
import { PredictionHistogramData } from "../../../types/interfaces";

interface RenderLegendProps extends LegendProps {
  data: PredictionHistogramData[];
  reversed?: boolean;
}

const Legend: FC<RenderLegendProps> = ({ data, reversed }) => {
  const legendClass = classNames({
    protein__legend: true,
    lg: true,
    prediction: true,
    reversed: reversed,
  });
  return (
    <ul className={legendClass}>
      {data?.map((entry, index) => (
        <li key={`item-${index}`} className="lg">
          <span
            className="legend__box"
            style={{
              background: entry.color,
            }}
          />
          <span className="legend__box-content">{entry.label}</span>
        </li>
      ))}
    </ul>
  );
};

export default Legend;
