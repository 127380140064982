import { useEffect } from "react";
import { Column } from "react-table";
import ReactTooltip from "react-tooltip";
import { useAppDispatch, useAppSelector } from "../../App/hooks";
import { getAnnotatedMutationsFileAsync } from "../../redux/asyncThunks/genomeThunks";
import { RootState } from "../../redux/store";
import BasicTable from "../../shared/table/BasicTable";
import { IMutationTable } from "../../types/interfaces";

const MutationsTable = ({ data }: { data: IMutationTable[] }) => {
  const url = useAppSelector(
    (state: RootState) => state.genome.annotatedMutationsFileUrl
  );
  const url2 = useAppSelector(
    (state: RootState) => state.genome.annotatedVcfFileUrl
  );
  const dispatch = useAppDispatch();

  const { cardCollapsed } = useAppSelector((state: RootState) => state.layout);

  const urlList = ((cardCollapsed === "genome") ?
        [{ downloadUrl: url, downloadName: "mutations.tsv"},
       { downloadUrl: url2, downloadName: "annotations.vcf"}]
       : [{ downloadUrl: url, downloadName: "mutations.tsv" }])

  useEffect(() => {
    dispatch(getAnnotatedMutationsFileAsync());
  }, []);

  const headers: Column<IMutationTable>[] = [
    {
      Header: "#CDS Position / Length",
      accessor: "CDS_position / CDS_len",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "Distance to feature",
      accessor: "Distance to feature",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "Effect",
      accessor: "Effect",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "Gene Name",
      accessor: "Gene Name",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "HGVS.p",
      accessor: "HGVS.p",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "ID",
      accessor: "ID",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "Protein Position / Length",
      accessor: "Protein_position / Protein_len",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "Putative impact",
      accessor: "Putative impact",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "Transcript biotype",
      accessor: "Transcript biotype",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "Variation Type",
      accessor: "Variation_type",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "Mutation Length",
      accessor: "mutation_length",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "cDNA Position / Length",
      accessor: "cDNA_position / cDNA_len",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "Mutation name",
      accessor: "mutation_name",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "Note",
      accessor: "note",
      Cell: (row) => {
        return (
          <span data-tip={row.value} style={{ maxWidth: "200px" }}>
            {row.value}
          </span>
        );
      },
    },
    {
      Header: "Protein Name",
      accessor: "protein_name",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "Sequence ID",
      accessor: "sequence_id",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "User Sequence ID",
      accessor: "user_seq_id",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
  ];

  return (
    <>
      <ReactTooltip
        place="left"
        type="dark"
        effect="solid"
        multiline={true}
        className="table__tooltip"
      />
      <BasicTable
        tableHeaders={headers}
        tableData={data}
        pagination
        header
        title="Mutation Information"
        restore
        exportFile
        urlList={urlList}
      />
    </>
  );
};

export default MutationsTable;
