import React from "react";
import { BiError } from "react-icons/bi";

const ErrorMessage = () => {
  return (
    <div className="error-message__container">
      <BiError className="error-message__icon" />
      <p className="error-message__text">Something went wrong!</p>
    </div>
  );
};

export default ErrorMessage;
