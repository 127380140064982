import React, { FC } from "react";
import { TooltipProps } from "recharts";

const Tooltip: FC<TooltipProps<number, string>> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="protein__tooltip">
        <p className="protein-name">{payload[0].payload.proteinName}</p>
        <p className="mutations">
          <span className="mutations__item">{`${payload[0].payload.mutation}`}</span>
        </p>
        <p className="occurrences">{`${payload[0].payload.occurrences}: ${payload[0].payload.startPos}`}</p>
      </div>
    );
  }
  return null;
};

export default Tooltip;
