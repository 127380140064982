import React from "react";
import Header from "./Header";
import Content from "./Content";

const AboutPage = () => {
  return (
    <div className="page">
      <Header />
      <Content />
    </div>
  );
};

export default AboutPage;
