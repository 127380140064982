import React, { useEffect } from "react";
import Select from "react-select";
import { useAppDispatch } from "../../App/hooks";
import { getMutationNameSuggestionsAsync } from "../../redux/asyncThunks/mutationsThunks";

export type SelectOption = {
  value: string;
  label: string;
};

type MultiSelectFieldProps = {
  onChange: Function;
  name: string;
  placeholder?: string;
  options?: Array<string>;
  value: any;
};

export const MultiSelectField = ({
  value,
  name,
  placeholder,
  options,
  onChange,
  ...other
}: MultiSelectFieldProps) => {
  const dispatch = useAppDispatch();

  const handleChange = (handleChangeValue: any) => {
    onChange(handleChangeValue);
  };

  const hadnleAsyncChange = (value: any) => {
    value !== "" && dispatch(getMutationNameSuggestionsAsync(value));
  };

  return (
    <Select
      isMulti
      name={name}
      value={value}
      onChange={handleChange}
      onInputChange={hadnleAsyncChange}
      options={options}
      blurInputOnSelect={false}
      isClearable
      closeMenuOnSelect={false}
      className="react-select"
      placeholder={placeholder}
      classNamePrefix="react-select"
      {...other}
    />
  );
};

type RenderMultiSelectFieldProps = {
  input: any;
  meta?: any;
  options?: Array<string>;
  placeholder?: string;
};

const RenderMultiSelectField = ({
  input,
  meta,
  options,
  placeholder,
}: RenderMultiSelectFieldProps) => {
  return (
    <div className="form__form-group-input-wrap">
      <MultiSelectField
        {...input}
        options={options}
        placeholder={placeholder}
      />
      {meta.touched && meta.error && (
        <span className="form__form-group-error">{meta.error}</span>
      )}
    </div>
  );
};

export default RenderMultiSelectField;
