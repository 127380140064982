import { VscArrowRight } from "react-icons/vsc";
import { useAppDispatch } from "../../App/hooks";
import { cardToggle, cardTypeChange } from "../../redux/slices/layoutSlice";

type PostCardProps = {
  imageSrc: string;
  title: string;
  text: string;
  cardType: "genome" | "mutation";
};

const PostCard = ({ imageSrc, title, text, cardType }: PostCardProps) => {
  const dispatch = useAppDispatch();

  return (
    <div
      className="postCard"
      onClick={() => {
        dispatch(cardToggle());
        dispatch(cardTypeChange(cardType));
      }}
    >
      <div className="postCard__image">
        <img src={imageSrc} alt="Card" />
      </div>
      <div className="postCard__content">
        <div className="postCard__title">{title}</div>
        <div className="postCard__text">{text}</div>
        <div className="postCard__footer">
          <div className="postCard__button">
            <VscArrowRight className="postCard__button-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostCard;
