import React from "react";
import { BsGithub } from "react-icons/bs";

const Sidebar = () => {
  return (
    <div className="research__body-side">
      <div className="authors-list">
        <div className="authors-container">
          <p className="authors__title">Authors</p>
          <ul>
            <li>
              <div className="list-initials">KR</div>
              <p className="list-name">Kalcsevszki Regina</p>
            </li>
            <li>
              <div className="list-initials">HA</div>
              <p className="list-name">Horváth András</p>
            </li>
            <li>
              <div className="list-initials">BG</div>
              <p className="list-name">Balázs Győrffy</p>
            </li>
            <li>
              <div className="list-initials">SP</div>
              <p className="list-name">Sándor Pongor</p>
            </li>
            <li>
              <div className="list-initials">BL</div>
              <p className="list-name" data-tip={"obalasz@gmail.com"}>
                Balázs Ligeti <span>*</span>
              </p>
            </li>
          </ul>
          <div className="footnote">* Email address is available</div>
        </div>
        <div className="authors-container">
         <p className="authors__title">Github</p>
          <li>
          <div className="list-initials"><BsGithub /></div>
            <a href="https://github.com/bio-apps/covid-outcome">Covid-outcome
            </a>
            </li>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
