import React, { FC } from "react";
import { TooltipProps } from "recharts";

const Tooltip: FC<TooltipProps<number, string>> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="protein__tooltip">
        <p className="protein-name">{label}</p>
        <p className="mutations">
          {payload[0].payload.mutations.map((entry: string, index: number) => (
            <span className="mutations__item">{`${entry}${
              payload[0].payload.mutations.length !== index + 1 ? "," : ""
            }`}</span>
          ))}
        </p>
        <p className="occurrences">{payload[0].payload.occurrences}</p>
      </div>
    );
  }
  return null;
};

export default Tooltip;
