import classNames from "classnames";
import React from "react";

type WizardNavigationProps = {
  children: any;
};

const WizardNavigation = ({ children }: WizardNavigationProps) => {
  return <div className="wizard-navigation">{children}</div>;
};

export default WizardNavigation;

type ItemProps = {
  number: number;
  pageNumber: number;
  title: string;
};
WizardNavigation.Item = function Item({
  number,
  pageNumber,
  title,
}: ItemProps) {
  const itemClass = classNames({
    "wizard-navigation__item": true,
    active: number === pageNumber,
    completed: pageNumber > number,
  });

  return (
    <div className={itemClass}>
      <div className="wizard-navigation__item-title">{title}</div>
    </div>
  );
};
