import { useEffect, useRef } from "react";

import Header from "./components/Header";
import PostCard from "./components/PostCard";
import cardImage1 from "../assets/img/covid_outcome_card.jpg";
import cardImage2 from "../assets/img/mutation_build.jpeg";
import { useAppDispatch } from "../App/hooks";
import { wakeUpCallAsync } from "../redux/asyncThunks/generalThunks";
import { setCardsRef } from "../utilities/refs";
import { ButtonToolbar } from "reactstrap";
import { testData } from "../assets/files/test";

const MainPage = () => {
  const cardsRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    setCardsRef(cardsRef);
  }, []);

  const scrollToCards = () => cardsRef?.current?.scrollIntoView();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(wakeUpCallAsync());
  }, []);

  return (
    <div className="page">
      <Header pageName="main">
        <Header.Title>CovidOutcome2</Header.Title>
        <div className="header__container" id={"home"}>
          <Header.Content contentText="A machine learning tool for the prediction of severety of a SARS-CoV-2 variant" />
          <ButtonToolbar style={{ alignSelf: "center" }}>
            <Header.Button
              buttonText="Start analysis!"
              onClickFunc={scrollToCards}
            />
          </ButtonToolbar>
        </div>
      </Header>
      <div className="page__intro section">
        <div className="page__intro">
          <h4>What is CovidOutcome2?</h4>
          <p>The application provides the following services:</p>
          <ul>
            <li>
              Applying state-of-the art machine learning techniques, autoML and
              deep-learning models for predicting the expected clinical outcome
              with expected accuracy of 0.795 CI: [0.762, 0.831]
            </li>
            <li>
              Predicting novel-unseen mutation combination effects based on the
              existing data using ML models
            </li>
            <li>
              Providing fast and high quality, annotated mutation calling for
              SARS-COV2 genomes in standard (VCF) format
            </li>
          </ul>
          <p>
            CovidOutcome2 is a collaboration between the{" "}
            <a href="https://gyorffy.semmelweis.hu/">
              Semmelweis University Dept. of Bioinformatics
            </a>{" "}
            and{" "}
            <a href="https://pongor2.itk.ppke.hu/">
              Bioinformatics research group of Pázmány Péter Catholic University
            </a>
          </p>
          <p>

            CovidOutcome2 is available on Github:

            <a href="https://github.com/bio-apps/covid-outcome"> https://github.com/bio-apps/covid-outcome</a>

          </p>
        </div>
        <hr />

        <h4 ref={cardsRef}>Choose from our latest analyses:</h4>
      </div>
      <div className="main-row section">
        <PostCard
          imageSrc={cardImage1}
          cardType="genome"
          title="Predicting the severity of a SARS-CoV-2 variant from genome sequences"
          text="Using this pipeline you can estimate the expected outcome of SARS-COV2 using state of the art ML models as well as
          analizing the mutation profile of the uploaded sequences."
        />
        <PostCard
          imageSrc={cardImage2}
          cardType="mutation"
          title="Define your own viral variants and estimate the expected clinical outcome"
          text="Submit your mutation profiles and get predictions for them. The model input is a set of mutations and the output of the pipeline
          is the estimated severity of the varints using the data observed prevously."
        />
      </div>
    </div>
  );
};

export default MainPage;
