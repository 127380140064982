import React from "react";
import { useAppDispatch, useAppSelector } from "../../../App/hooks";
import { changeAnalysisType } from "../../../redux/slices/genome";
import AnalysisTypeItem from "../../Genome/components/AnalysisTypeItem";

const MutationAnalysisType = () => {
  const { analysisType } = useAppSelector((state) => state.genome);
  const dispatch = useAppDispatch();

  return (
    <div className="genome-sequence modal-form__subpage">
      <AnalysisTypeItem
        analysisType={analysisType}
        itemType={"automl"}
        header="Automated machine learning provided by JADBio (best AUC=0.88, best ACC=0.83)"
        description="Use interpretable, regularized prediction model created by JadBIO autoML tool. 
        The output is a predicted probability of the severity. 
        This model uses feature selection (Test-Budgeted  Statistically Equivalent Signature (SES) algorithm) with regularized logistic regression model. 
        The estimated prediction accuracy is 0.83 (CI: [0.79,0.86] ), and AUC (area under the ROC curve) is 0.88 (CI: [0.857, 0.90]) for samples with age, and acc=0.71 CI: [0.68, 0.7],  
        AUC = 0.78 (CI: [0.75, 0.82]) for samples without age is (based on 10 repeated 10-fold cross validation adjusted with Bootstrap Bias Corrected CV). "
        clicked={() => dispatch(changeAnalysisType("automl"))}
      />
      <AnalysisTypeItem
        analysisType={analysisType}
        itemType={"deep"}
        header="Deep Learning model (best AUC=0.88, best ACC=0.81)"
        description="Use deep learning model. The trained neural network contains 5 hidden Leaky Rectified Linear layers (ReLU). 
        The model predicts a probability of the severity (sample class is Severe). The estimated prediction accuracy is 0.81 (CI: [0.8,0.83] ),
         and AUC (area under the ROC curve) is 0.88 (CI: [0.87, 0.90]) (CI: []) for samples with age, and acc=0.75 CI: [0.72, 0.78],  AUC = 0.82 (CI: [0.8, 0.85])
          for samples without age is (based on 10 repeated 5-fold cross validation)"
        clicked={() => dispatch(changeAnalysisType("deep"))}
      />
      <div>
        For further description about the applied methods and parameters see the{" "}
        <a href="https://github.com/bio-apps/covid-outcome/wiki">wiki</a>{" "}
      </div>
    </div>
  );
};

export default MutationAnalysisType;
