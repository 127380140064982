import { sampleSequence } from "../assets/files/sampleGenomeSequence";

export const sampleSequencePopulate = (
  pageTitle: any,
  state: any,
  obj: { setIn: any; changeValue: any }
) => {
  const genomeSequenceText = state.fields["genomeSequenceText"];
  genomeSequenceText.change(sampleSequence);
};

export const sampleMutationPopulate = (
  pageTitle: any,
  state: any,
  obj: { setIn: any; changeValue: any }
) => {
  const mutationVarian = state.fields["mutation1"];
  mutationVarian.change([
    { value: "Spike_p.A67V", label: "Spike_p.A67V"},
    { value: "Spike_p.H69_V70del", label: "Spike_p.H69_V70del"},
    { value: "Spike_p.T95I", label: "Spike_p.T95I"},
    { value: "Spike_p.G142D", label: "Spike_p.G142D"},
    { value: "Spike_p.Y144_Y145del", label: "Spike_p.Y144_Y145del"},
    { value: "Spike_p.L212I", label: "Spike_p.L212I"},
    { value: "Spike_p.R214_D215insEPE", label: "Spike_p.R214_D215insEPE"},
    { value: "Spike_p.G339D", label: "Spike_p.G339D"},
    { value: "Spike_p.S371L", label: "Spike_p.S371L"},
    { value: "Spike_p.S373P", label: "Spike_p.S373P"},
    { value: "Spike_p.S375F", label: "Spike_p.S375F"},
    { value: "Spike_p.K417N", label: "Spike_p.K417N"},
    { value: "Spike_p.N440K", label: "Spike_p.N440K"},
    { value: "Spike_p.G446S", label: "Spike_p.G446S"},
    { value: "Spike_p.S477N", label: "Spike_p.S477N"},
    { value: "Spike_p.T478K", label: "Spike_p.T478K"},
    { value: "Spike_p.E484A", label: "Spike_p.E484A"},
    { value: "Spike_p.Q493R", label: "Spike_p.Q493R"},
    { value: "Spike_p.G496S", label: "Spike_p.G496S"},
    { value: "Spike_p.Q498R", label: "Spike_p.Q498R"},
    { value: "Spike_p.N501Y", label: "Spike_p.N501Y"},
    { value: "Spike_p.Y505H", label: "Spike_p.Y505H"},
    { value: "Spike_p.T547K", label: "Spike_p.T547K"},
    { value: "Spike_p.D614G", label: "Spike_p.D614G"},
    { value: "Spike_p.H655Y", label: "Spike_p.H655Y"},
    { value: "Spike_p.N679K", label: "Spike_p.N679K"},
    { value: "Spike_p.P681H", label: "Spike_p.P681H"},
    { value: "Spike_p.N679K", label: "Spike_p.N679K"},
    { value: "Spike_p.N764K", label: "Spike_p.N764K"},
    { value: "Spike_p.D796Y", label: "Spike_p.D796Y"},
    { value: "Spike_p.N856K", label: "Spike_p.N856K"},
    { value: "Spike_p.Q954H", label: "Spike_p.Q954H"},
    { value: "Spike_p.N969K", label: "Spike_p.N969K"},
    { value: "Spike_p.L981F", label: "Spike_p.L981F"},
    { value: "NSP3_p.K38R", label: "NSP3_p.K38R"},
    { value: "NSP3_p.V1069I", label: "NSP3_p.V1069I"},
    { value: "NSP3_p.S1265del", label: "NSP3_p.S1265del"},
    { value: "NSP3_p.L1266I", label: "NSP3_p.L1266I"},
    { value: "NSP3_p.A1892T", label: "NSP3_p.A1892T"},
    { value: "NSP4_p.T492Il", label: "NSP4_p.T492I"},
    { value: "NSP5_p.P132H", label: "NSP5_p.P132H"},
    { value: "NSP6_p.L105_G107del", label: "NSP6_p.L105_G107del"},
    { value: "NSP12_p.P323L", label: "NSP12_p.P323L"},
    { value: "NSP14_p.I42V", label: "NSP14_p.I42V"},
    { value: "E_p.T9I", label: "E_p.T9I"},
    { value: "M_p.D3G", label: "M_p.D3G"},
    { value: "M_p.Q19E", label: "M_p.Q19E"},
    { value: "M_p.A63T", label: "M_p.A63T"},
    { value: "N_p.P13L", label: "N_p.P13L"},
    { value: "N_p.RG203KR", label: "N_p.R203K"},
  ]);
};
