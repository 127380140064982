import React, { useEffect, useState } from "react";
import { Field } from "react-final-form";

import { useAppDispatch } from "../../../App/hooks";
import {
  setAgesAsync,
  uploadAgeFileAsync,
} from "../../../redux/asyncThunks/genomeThunks";
import DropZoneField from "../../../shared/form/DropZoneField";
import FieldOverride from "../../../shared/form/FieldOverride";

const GenomeAge = () => {
  const [query, setQuery] = useState("");

  const handleChange = (event: any) => {
    setQuery(event.target.value);
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    let timeOutRequest: ReturnType<typeof setTimeout>;
    if (query !== "") {
      timeOutRequest = setTimeout(() => dispatch(setAgesAsync(query)), 500);
    }
    return () => {
      clearTimeout(timeOutRequest);
    };
  }, [dispatch, query]);

  const fileAsync = async (file: FileList) => {
    const formData = new FormData();
    formData.append("file", file[0]);

    dispatch(uploadAgeFileAsync(formData));
  };

  return (
    <div className="genome-sequence modal-form__subpage">
      <div className="form__form-group">
        <p>
          Note: setting the age for samples significantly increase the
          prediction accuracy
        </p>
        <p>
          The age should be set in the same order as the sequences. If the age
          is unkown for a sample, leave it empty.{" "}
        </p>
        <span className="form__form-group-label">Patient's age</span>
        <div className="form__form-group-field">
          <Field name="genomeAge">
            {(props: any) => (
              <FieldOverride input={props} inputOnChange={handleChange} />
            )}
          </Field>
        </div>
      </div>

      <p className="modal-form__subpage__or">OR</p>

      <Field name="genomeAgeFile">
        {({ input, meta }: any) => (
          <DropZoneField
            input={input}
            onChangeRequest={fileAsync}
            acceptedFileTypes={".tsv, .txt, .xls, .xlsx"}
          />
        )}
      </Field>
    </div>
  );
};

export default GenomeAge;
