import { FC } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import RenderLegend from "./Legend";
import RenderTooltip from "./Tooltip";
import { MutationsByProteinData } from "../../../types/interfaces";

interface MutationsByProteinProps {
  height: number;
  width: number;
  reversed?: boolean;
  data: MutationsByProteinData[];
}

const handleHoverOn = (o: any) => {
  console.log("hovering on");
};

const handleHoverOff = (o: any) => {
  console.log("hovering off");
};

const MutationsByProtein: FC<MutationsByProteinProps> = ({
  height,
  width,
  reversed,
  data,
}) => {
  return (
    <BarChart
      height={height}
      width={width}
      data={data}
      margin={reversed ? { right: 100, bottom: 10 } : { left: 50, bottom: 10 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="proteinName" angle={45} interval={0} dy={10} />
      <YAxis />
      <Tooltip content={RenderTooltip} />
      <Legend
        content={<RenderLegend reversed={reversed} data={data} />}
        verticalAlign="middle"
        onMouseEnter={handleHoverOn}
        onMouseLeave={handleHoverOff}
      />
      <Bar dataKey="occurrences" fillOpacity="0.7">
        {data.map((entry, index) => {
          return (
            <Cell
              key={`cell-${index}`}
              fill={entry.color}
              stroke={entry.color}
              strokeWidth={2}
            />
          );
        })}
      </Bar>
    </BarChart>
  );
};

export default MutationsByProtein;
