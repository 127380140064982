import React, { FC } from "react";

type ContentBlockProps = {
  header: string;
  children: React.ReactNode;
};

const ContentBlock: FC<ContentBlockProps> = ({ header, children }) => {
  return (
    <div className="block">
      <h4>{header}</h4>
      {children}
    </div>
  );
};

export default ContentBlock;
