export const randomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const groupByProteinName = (array: any[], key: string) => {
  const tempResult = array.reduce((tempObject, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (tempObject[currentValue[key]] = tempObject[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate

    return tempObject;
  }, {});
  return Object.entries(tempResult).map((e) => ({
    name: e[0],
    data: e[1],
  }));
};
