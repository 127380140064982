import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FaFileContract } from "react-icons/fa";

const AcceptedFile = ({ name, size }: { name: string; size: number }) => {
  return (
    <div className="accepted-file__container">
      <FaFileContract className="accepted-file__svg" />
      <span className="accepted-file__title">{name}</span>
      <span className="accepted-file__size">{size / 1000} KB</span>
    </div>
  );
};

type DropZoneFieldProps = {
  input: any;
  onChangeRequest?: any;
  acceptedFileTypes: string;
};

const DropZoneField = ({
  input,
  onChangeRequest,
  acceptedFileTypes,
}: DropZoneFieldProps) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      input.onChange(acceptedFiles);
      onChangeRequest && onChangeRequest(acceptedFiles);
    },
    [input, onChangeRequest]
  );

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({ onDrop });

  return (
    <div className="dropzone__container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {isDragActive ?? acceptedFiles.length === 0 ? (
          <p>Drop the files here ...</p>
        ) : acceptedFiles.length !== 0 ? (
          <AcceptedFile
            name={acceptedFiles[0].name}
            size={acceptedFiles[0].size}
          />
        ) : (
          <p>Drag 'n' drop a sequence file, or click to select file</p>
        )}
      </div>
      <div className="dropzone__files">
        {`Accepted file types: ${acceptedFileTypes}`}
      </div>
    </div>
  );
};

export default DropZoneField;
