import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import layoutReducer from "./slices/layoutSlice";
import genomeReducer from "./slices/genome";
import mutationsReducer from "./slices/mutations";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    genome: genomeReducer,
    layout: layoutReducer,
    mutations: mutationsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
