export const testData = [
  {
    proteinName: "UTR",
    mutation: "CHR_START-ORF1ab_n.241C>T",
    occurrences: 1,
    startPos: 241,
    endPos: 242,
  },
  {
    proteinName: "UTR",
    mutation: "E-M_n.26492A>T",
    occurrences: 1,
    startPos: 26492,
    endPos: 26493,
  },
  {
    proteinName: "UTR",
    mutation: "ORF8-N_n.28272A>T",
    occurrences: 25,
    startPos: 28272,
    endPos: 28273,
  },
  {
    proteinName: "NSP3",
    mutation: "NSP3_p.T237A",
    occurrences: 1,
    startPos: 3428,
    endPos: 3429,
  },
  {
    proteinName: "NSP3",
    mutation: "NSP3_p.T720I",
    occurrences: 1,
    startPos: 4878,
    endPos: 4879,
  },
  {
    proteinName: "NSP4",
    mutation: "NSP4_p.G196S",
    occurrences: 7,
    startPos: 9140,
    endPos: 9141,
  },
  {
    proteinName: "NSP4",
    mutation: "NSP4_p.T492I",
    occurrences: 1,
    startPos: 10029,
    endPos: 10030,
  },
  {
    proteinName: "NSP6",
    mutation: "NSP6_p.Q160R",
    occurrences: 14,
    startPos: 11451,
    endPos: 11452,
  },
  {
    proteinName: "NSP12",
    mutation: "NSP12_p.P323L",
    occurrences: 11,
    startPos: 14408,
    endPos: 14409,
  },
  {
    proteinName: "NSP12",
    mutation: "NSP12_p.Y521C",
    occurrences: 1,
    startPos: 15002,
    endPos: 15003,
  },
  {
    proteinName: "NSP13",
    mutation: "NSP13_p.E261D",
    occurrences: 1,
    startPos: 17019,
    endPos: 17020,
  },
  {
    proteinName: "NSP13",
    mutation: "NSP13_p.P419S",
    occurrences: 1,
    startPos: 17491,
    endPos: 17492,
  },
  {
    proteinName: "NSP15",
    mutation: "NSP15_p.S261L",
    occurrences: 12,
    startPos: 20402,
    endPos: 20403,
  },
  {
    proteinName: "Spike",
    mutation: "Spike_p.T95I",
    occurrences: 4,
    startPos: 21846,
    endPos: 21847,
  },
  {
    proteinName: "Spike",
    mutation: "Spike_p.V143_Y144insT",
    occurrences: 1,
    startPos: 21991,
    endPos: 21994,
  },
  {
    proteinName: "Spike",
    mutation: "Spike_p.Y145N",
    occurrences: 1,
    startPos: 21995,
    endPos: 21996,
  },
  {
    proteinName: "Spike",
    mutation: "Spike_p.R346K",
    occurrences: 1,
    startPos: 22599,
    endPos: 22600,
  },
  {
    proteinName: "Spike",
    mutation: "Spike_p.E484K",
    occurrences: 11,
    startPos: 23012,
    endPos: 23013,
  },
  {
    proteinName: "Spike",
    mutation: "Spike_p.N501Y",
    occurrences: 1,
    startPos: 23063,
    endPos: 23064,
  },
  {
    proteinName: "Spike",
    mutation: "Spike_p.D614G",
    occurrences: 1,
    startPos: 23403,
    endPos: 23404,
  },
  {
    proteinName: "Spike",
    mutation: "Spike_p.P681H",
    occurrences: 1,
    startPos: 23604,
    endPos: 23605,
  },
  {
    proteinName: "Spike",
    mutation: "Spike_p.D950N",
    occurrences: 17,
    startPos: 24410,
    endPos: 24411,
  },
  {
    proteinName: "Spike",
    mutation: "Spike_p.M1229I",
    occurrences: 1,
    startPos: 25249,
    endPos: 25250,
  },
  {
    proteinName: "NS3a",
    mutation: "NS3a_p.I20M",
    occurrences: 1,
    startPos: 25452,
    endPos: 25453,
  },
  {
    proteinName: "NS3a",
    mutation: "NS3a_p.Q57H",
    occurrences: 1,
    startPos: 25563,
    endPos: 25564,
  },
  {
    proteinName: "NS3a",
    mutation: "NS3a_p.V256fs",
    occurrences: 1,
    startPos: 26159,
    endPos: 26163,
  },
  {
    proteinName: "NS8",
    mutation: "NS8_p.T11K",
    occurrences: 1,
    startPos: 27925,
    endPos: 27926,
  },
  {
    proteinName: "NS8",
    mutation: "NS8_p.P38S",
    occurrences: 18,
    startPos: 28005,
    endPos: 28006,
  },
  {
    proteinName: "NS8",
    mutation: "NS8_p.S67F",
    occurrences: 1,
    startPos: 28093,
    endPos: 28094,
  },
  {
    proteinName: "N",
    mutation: "N_p.T205I",
    occurrences: 1,
    startPos: 28887,
    endPos: 28888,
  },
];
