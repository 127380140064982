import { FC } from "react";
import classNames from "classnames";
import { LegendProps } from "recharts";
import { MutationsByProteinData } from "../../../types/interfaces";

interface RenderLegendProps extends LegendProps {
  reversed?: boolean;
  data: MutationsByProteinData[];
}

const Legend: FC<RenderLegendProps> = ({ reversed, data }) => {
  const legendClass = classNames({
    protein__legend: true,
    reversed: reversed,
  });

  return (
    <ul className={legendClass}>
      {data.map((entry, index) => (
        <li key={`item-${index}`}>
          <span
            className="legend__box"
            style={{
              background: entry.color,
              opacity: 0.8,
            }}
          />
          {entry.proteinName}
        </li>
      ))}
    </ul>
  );
};

export default Legend;
