import { Route, Routes } from "react-router";

import "bootstrap/dist/css/bootstrap.min.css";
import "../scss/app.scss";
import WrappedRoutes from "./WrappedRoutes";
import MenuModal from "../layout/MenuModal";
import ModalForm from "../ModalForm";
import MainPage from "../MainPage";
import AboutPage from "../ResearchPage";
import ResultPage from "../ResultsPage";
import ContactsPage from "../ContactsPage";

function App() {
  return (
    <div className="App">
      <main>
        <Routes>
          <Route path="/" element={<WrappedRoutes />}>
            <Route index element={<MainPage />} />
            <Route path="research" element={<AboutPage />} />
            <Route path="results" element={<ResultPage />} />
            {/* <Route path="contacts" element={<ContactsPage />} /> */}
          </Route>
        </Routes>
      </main>
      {/* <MenuModal /> */}
      <ContactsPage />
      <ModalForm />
    </div>
  );
}

export default App;
