import { FC, useEffect, useState } from "react";
import { Field, useFormState } from "react-final-form";

import { useAppDispatch, useAppSelector } from "../../../App/hooks";
import {
  checkGenomeSequenceFileAsync,
  checkGenomeSequenceTextAsync,
} from "../../../redux/asyncThunks/genomeThunks";
import { onIsSequenceTextareaFailedChange } from "../../../redux/slices/genome";
import DropZoneField from "../../../shared/form/DropZoneField";
import FieldOverride from "../../../shared/form/FieldOverride";

interface GenomeSequenceProps {
  values: any;
  mutators: any;
}

const GenomeSequence: FC<GenomeSequenceProps> = ({ mutators }) => {
  const { isSequenceTextareaFailed } = useAppSelector((state) => state.genome);

  const { values } = useFormState();
  const { sampleSequencePopulate } = mutators;

  // If textarea touched
  const [visited, setVisited] = useState(false);

  const dispatch = useAppDispatch();

  // Runs api call 0.5 seconds after user stoped typing
  useEffect(() => {
    let timeOutRequest: ReturnType<typeof setTimeout>;
    if (values.genomeSequenceText !== "" || undefined) {
      timeOutRequest = setTimeout(() => {
        dispatch(checkGenomeSequenceTextAsync(values.genomeSequenceText));
      }, 1000);
    }

    return () => {
      clearTimeout(timeOutRequest);
    };
  }, [values["genomeSequenceText"]]);

  const fileAsync = async (file: FileList) => {
    const formData = new FormData();
    formData.append("file", file[0]);

    dispatch(onIsSequenceTextareaFailedChange(false));
    dispatch(checkGenomeSequenceFileAsync(formData));
  };

  return (
    <div className="genome-sequence modal-form__subpage">
      <span className="form__form-group-label">Upload a fasta file:</span>
      <Field name="genomeSequenceFile">
        {({ input, meta }: any) => (
          <DropZoneField
            input={input}
            onChangeRequest={fileAsync}
            acceptedFileTypes={".fa, .fasta,.fna, .ffn, .faa, .frn"}
          />
        )}
      </Field>
      <p className="modal-form__subpage__or">OR</p>

      <div className="form__form-group">
        <span className="form__form-group-label">
          Insert as text. (or use{" "}
          {/* On click will populate textarea with sample data */}
          <span onClick={sampleSequencePopulate}>this sample</span>)
        </span>
        <div className="form__form-group-field">
          <Field name="genomeSequenceText">
            {({ input, meta }) => (
              <div className="form__form-group-input-wrap">
                <textarea
                  name={input.name}
                  onChange={input.onChange}
                  value={input.value}
                  onBlur={() => setVisited(true)}
                />
                {isSequenceTextareaFailed && visited ? (
                  <span className="form__form-group-error">
                    Invalid input sequence data.No valid sequence in the input
                    data. Please check the input!
                  </span>
                ) : null}
              </div>
            )}
          </Field>
        </div>
      </div>
    </div>
  );
};

export default GenomeSequence;
