import classNames from "classnames";
import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useAppSelector } from "../../App/hooks";
import { RootState } from "../../redux/store";
import MlTable from "./MlTable";
import MutationsTable from "./MutationsTable";
import SequenceTable from "./SequenceTable";

const TableTabs = () => {
  const [activeTab, setActiveTab] = useState("1");

  const activeTabToggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { filteredMutationTableData, sequenceTableData, mlResultData } =
    useAppSelector((state: RootState) => state.genome);

  return (
    <div className="tabs tabs--border-bottom">
      <div className="tabs__wrap" style={{ minWidth: "initial" }}>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === "1" })}
              onClick={() => activeTabToggle("1")}
            >
              ML Table
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === "2" })}
              onClick={() => activeTabToggle("2")}
            >
              Sequence Table
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === "3" })}
              onClick={() => activeTabToggle("3")}
            >
              Mutations Table
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {mlResultData ? <MlTable data={mlResultData} /> : null}
          </TabPane>
          <TabPane tabId="2">
            {sequenceTableData ? (
              <SequenceTable data={sequenceTableData} />
            ) : null}
          </TabPane>
          <TabPane tabId="3">
            {filteredMutationTableData ? (
              <MutationsTable data={filteredMutationTableData} />
            ) : null}
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default TableTabs;
