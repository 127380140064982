import React, { useState } from "react";
import { BsTwitter, BsGithub } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../App/hooks";
import { menuToggle, selectMenuState } from "../../redux/slices/layoutSlice";
import itkLogo from "../../assets/img/ITK_logo_color_transparent.png";
import { getCardsRef } from "../../utilities/refs";

const Sidebar = () => {
  const [isHovered, setIsHovered] = useState(false);

  const cardsRef = getCardsRef();

  const isCollapsed = useAppSelector(selectMenuState);
  const dispatch = useAppDispatch();

  const onHoverToggle = () => {
    setIsHovered(!isHovered);
  };

  const sidebarClass = classNames({
    sidebar: true,
    collapsed: isCollapsed,
    hovered: isHovered,
  });

  return (
    <div className={sidebarClass}>
      <div className="sidebar__container">
        <div className="sidebar__header">
          <div className="sidebar__logo">
            <Link to="/" className="image">
              <img src={itkLogo} alt="ITK logo" />
            </Link>
          </div>
          {/* <div
            className="sidebar__menu-btn"
            onClick={() => dispatch(menuToggle())}
            onMouseEnter={onHoverToggle}
            onMouseLeave={onHoverToggle}
          >
            <span className="menu-btn-line"></span>
            <span className="menu-btn-line"></span>
            <span className="menu-btn-line"></span>
          </div> */}
        </div>
        <div className="sidebar__content">
          <nav className="sidebar__navigation">
            <ul className="sidebar__pages">
              <Link to="/">
                <li className="sidebar__navigation-item">Start</li>
              </Link>
              <Link to="/research">
                <li className="sidebar__navigation-item">About</li>
              </Link>
              {/* <Link to="/contacts"> */}
              <li
                className="sidebar__navigation-item"
                onClick={() => dispatch(menuToggle())}
              >
                Contacts
              </li>
              {/* </Link> */}
            </ul>
          </nav>
        </div>
        <div className="sidebar__socials">
          {/*
            Adding github instead: the icon: ../../assets/img/GitHub-Mark-Light-64px.png
        */}
          <div className="socials-item">
            <a href="https://github.com/bio-apps/covid-outcome">
              <BsGithub />
            </a>
          </div>
          {/*
          <div className="socials-item">
            <BsTwitter />
          </div>
          <div className="socials-item">
            <BsYoutube />
          </div>
          <div className="socials-item">
            <FaLinkedinIn />
          </div>
          */}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
