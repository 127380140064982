import { useEffect } from "react";

import { useAppDispatch } from "../App/hooks";
import Header from "../MainPage/components/Header";
import {
  downloadMlResultsSummaryPlotUrlAsync,
  downloadMutationPlotSvgUrlAsync,
  downloadMutationStatPlotUrlAsync,
} from "../redux/asyncThunks/genomeThunks";
import ResultsTabs from "./components/ResultsTabs";

const ResultPage = () => {
  const dispatch = useAppDispatch();

  // useEffect(() => {
  // dispatch(downloadMutationPlotSvgUrlAsync());
  // dispatch(downloadMlResultsSummaryPlotUrlAsync());
  // dispatch(downloadMutationStatPlotUrlAsync());
  // }, []);

  return (
    <div className="page">
      <Header pageName="main">
        <Header.Title>CovidOutcome2</Header.Title>
        <div className="header__container section">
          <Header.Content contentText="Results" />
        </div>
      </Header>
      <ResultsTabs />
    </div>
  );
};

export default ResultPage;
