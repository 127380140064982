import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../App/hooks";
import {
  createSessionAsync,
  getFilteredMutationInfoTableAsync,
  getMutationHistogramPlotDataAsync,
  getPredictionHistogramDataAsync,
  getProteinBarPlotDataAsync,
  getSequenceInfoTableAsync,
  getMLResultsFileAsync,
  runMlAsync,
} from "../../redux/asyncThunks/genomeThunks";
import { createArtificialCovidGenomeDataAsync } from "../../redux/asyncThunks/mutationsThunks";
import { onMlFinishedToggle } from "../../redux/slices/genome";
import { cardToggle } from "../../redux/slices/layoutSlice";
import WizardForm from "../../shared/form/WizardForm";
import WizardNavigation from "../../shared/form/WizardNavigation";
import Loading from "../../shared/Loading";
import ErrorMessage from "./components/ErrorMessage";
import MutationAnalysisType from "./components/MutationAnalysisType";
import MutationsAge from "./components/MutationsAge";
import MutationsSubpage from "./components/MutationsSubpage";

const Mutation = () => {
  const [activePage, setActivePage] = useState(0);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { analysisType, mlFinished, status } = useAppSelector(
    (state) => state.genome
  );

  const nextSubPage = (children: React.ReactChildren[], values: any) => {
    if (activePage === 0) {
      const keys = Object.keys(values);
      let result: Array<Array<string>> = [];
      keys.map((e) => {
        result.push(values[e].map((i: any) => i.value));
      });
      try {
        dispatch(createArtificialCovidGenomeDataAsync(result));
        setActivePage(Math.min(activePage + 1, children.length - 1));
      } catch (error: any) {
        console.log(error.message);
      }
    } else {
      setActivePage(Math.min(activePage + 1, children.length - 1));
    }
  };
  const prevPage = () => {
    setActivePage(Math.max(activePage - 1, 0));
  };

  useEffect(() => {
    dispatch(createSessionAsync());
  }, [dispatch]);

  useEffect(() => {
    if (mlFinished) {
      // Graphs
      dispatch(getProteinBarPlotDataAsync());
      dispatch(getPredictionHistogramDataAsync());
      dispatch(getMutationHistogramPlotDataAsync());
      // Tables
      dispatch(getSequenceInfoTableAsync());
      dispatch(getFilteredMutationInfoTableAsync());
      // Files
      dispatch(getMLResultsFileAsync());
      // Navigation
      navigate("/results");
      dispatch(cardToggle());
      dispatch(onMlFinishedToggle());
    }
  }, [mlFinished]);

  const handleSubmit = () => {
    dispatch(runMlAsync(analysisType));
    setActivePage(3);
  };

  return (
    <div className="modal-form__container">
      <WizardNavigation>
        <WizardNavigation.Item
          number={0}
          pageNumber={activePage}
          title="Mutation"
        />
        <WizardNavigation.Item number={1} pageNumber={activePage} title="Age" />
        <WizardNavigation.Item
          number={2}
          pageNumber={activePage}
          title="ML model"
        />
        <WizardNavigation.Item
          number={3}
          pageNumber={activePage}
          title="Results"
        />
      </WizardNavigation>
      <div className="modal-form__subpage-title">Mutation</div>
      <WizardForm
        onSubmit={handleSubmit}
        page={activePage}
        nextSubPage={nextSubPage}
        prevPage={prevPage}
      >
        <WizardForm.Page>
          {({ values, mutators }: any) => {
            return <MutationsSubpage mutators={mutators} values={values} />;
          }}
        </WizardForm.Page>
        <WizardForm.Page>
          {({ values, mutators }: any) => {
            return <MutationsAge />;
          }}
        </WizardForm.Page>
        <WizardForm.Page>
          {({ values, mutators }: any) => {
            return <MutationAnalysisType />;
          }}
        </WizardForm.Page>
        <WizardForm.Page>
          {({ values, mutators }: any) => {
            return status === "failed" ? <ErrorMessage /> : <Loading />;
          }}
        </WizardForm.Page>
      </WizardForm>
    </div>
  );
};

export default Mutation;
