import { createAsyncThunk } from "@reduxjs/toolkit";
import { wakeUpCall } from "../../api/general";
import { tableauSignin } from "../../api/tableau";

export const wakeUpCallAsync = createAsyncThunk(
  "general/wakeUpCall",
  async () => {
    console.log("making wake up call");
    await wakeUpCall();
  }
);

// export const tableauSigninAsync = createAsyncThunk(
//   "tableau/signin",
//   async () => {
//     const response = await tableauSignin();
//     console.log(response.data);
//   }
// );
