import { FieldRenderProps } from "react-final-form";

type FieldOverrideProps = {
  input: FieldRenderProps<string>;
  inputOnChange: any;
};

const FieldOverride = ({ input, inputOnChange }: FieldOverrideProps) => {
  const inputProps = {
    ...input,

    onChange: (e: any) => {
      inputOnChange && inputOnChange(e);
    },
  };

  return (
    <div className="form__form-group-input-wrap">
      <textarea {...inputProps} name={inputProps.input.name} />
    </div>
  );
};

export default FieldOverride;
