import classNames from "classnames";
import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useAppSelector } from "../../App/hooks";
import { RootState } from "../../redux/store";
import MutationsByPositions from "../../shared/charts/MutationsByPositions";
import MutationsByProtein from "../../shared/charts/MutationsByProtein";
import PredictionHistogram from "../../shared/charts/PredictionHistogram";

const GraphTabs = () => {
  const [activeTab, setActiveTab] = useState("1");

  const activeTabToggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const {
    proteinBarPlotData,
    mutationHistrogramPlotData,
    predictionHistogramData,
  } = useAppSelector((state: RootState) => state.genome);

  return (
    <div className="tabs tabs--border-bottom" style={{ width: "100%" }}>
      <div className="tabs__wrap">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === "1" })}
              onClick={() => activeTabToggle("1")}
            >
              ML Graph
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === "2" })}
              onClick={() => activeTabToggle("2")}
            >
              Mutations Graphs
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="graphs__container">
              <div className="graph__item-container">
                <div className="graph__item-title">Prediction Histogram</div>

                <PredictionHistogram
                  height={400}
                  width={700}
                  data={predictionHistogramData}
                />
              </div>
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="graphs__container">
              <div className="graph__item-container">
                <div className="graph__item-title">Mutations by protein</div>
                <MutationsByProtein
                  height={400}
                  width={700}
                  data={proteinBarPlotData}
                  reversed
                />
              </div>
              <div className="graph__item-container">
                <div className="graph__item-title">Mutations by position</div>

                <MutationsByPositions
                  height={400}
                  width={700}
                  data={mutationHistrogramPlotData}
                  reversed
                />
              </div>
            </div>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default GraphTabs;
