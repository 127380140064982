import api from "./api";

export const setAges = (ageList: string) => {
  return api.post("/set_sample_ages_char", {
    age_list: ageList,
  });
};

export const uploadAgeFile = (file: FormData) => {
  return api.post("/upload_age_file", file);
};
