import { useAppDispatch, useAppSelector } from "../App/hooks";
import {
  cardToggle,
  selectCardState,
  selectCardType,
} from "../redux/slices/layoutSlice";
import ModalContainer from "../shared/ModalContainer";
import Genome from "./Genome";
import Mutation from "./Mutation";

const ModalForm = () => {
  const isOpen = useAppSelector(selectCardState);
  const cardType = useAppSelector(selectCardType);
  const dispatch = useAppDispatch();
  const modalToggle = () => {
    dispatch(cardToggle());
  };

  return (
    <>
      <ModalContainer toggle={modalToggle} isOpen={isOpen} size="lg">
        {cardType === "genome" ? <Genome /> : <Mutation />}
      </ModalContainer>
    </>
  );
};

export default ModalForm;
