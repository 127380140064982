import React from "react";

type ButtonProps = {
  name?: string;
  title?: string;
  onClick?: () => void;
  type?: "reset" | "submit" | "button";
  style?: React.CSSProperties;
  disabled?: boolean;
};

const Button = ({
  name,
  title,
  onClick,
  type,
  style,
  disabled,
}: ButtonProps) => {
  return (
    <button
      className="btn__yellow"
      name={name}
      onClick={onClick}
      disabled={disabled}
      type={type}
      style={style}
    >
      {title}
    </button>
  );
};

export default Button;
