import React, { useEffect, useState } from "react";
import { Column } from "react-table";
import ReactTooltip from "react-tooltip";
import { useAppDispatch, useAppSelector } from "../../App/hooks";
import { getQcControlledSequenceFileAsync } from "../../redux/asyncThunks/genomeThunks";
import { RootState } from "../../redux/store";
import BasicTable from "../../shared/table/BasicTable";
import { ISequenceInfoTable } from "../../types/interfaces";

const SequenceTable = ({ data }: { data: ISequenceInfoTable[] }) => {
  const sequrl = useAppSelector(
    (state: RootState) => state.genome.qcControlledSequenceFileUrl
  );
  const qcresultsurl = useAppSelector(
    (state: RootState) => state.genome.qcResultsFileUrl
  );

  const dispatch = useAppDispatch();
  const { cardCollapsed } = useAppSelector((state: RootState) => state.layout);

  //useEffect(() => {
  //  dispatch(getQcControlledSequenceFileAsync());
  //}, []);

  const headers: Column<ISequenceInfoTable>[] = [
    {
      Header: "User ID",
      accessor: "user_seq_id",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "Sequence Length",
      accessor: "sequence_length",
    },
    {
      Header: "Sequence Header",
      accessor: "user_fasta_header",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "Sequence ID",
      accessor: "sequence_id",
      Cell: (row) => {
        return <span data-tip={row.value}>{row.value}</span>;
      },
    },
    {
      Header: "Ids",
      accessor: "check_ids",
    },
    {
      Header: "Uppercase",
      accessor: "check_uppercase",
    },
    {
      Header: "Sequence Length",
      accessor: "check_sequence_length",
    },
    {
      Header: "ACTG Proportion",
      accessor: "check_ACTG_proportion",
    },
    {
      Header: "GC Content",
      accessor: "check_GC_content",
    },
    {
      Header: "Congruence",
      accessor: "check_congruence",
    },
    {
      Header: "Summary",
      accessor: "qc_summary",
    },
  ];
  return (
    <>
      <ReactTooltip
        place="left"
        type="dark"
        effect="solid"
        multiline={true}
        className="table__tooltip"
      />
      <BasicTable
        tableHeaders={headers}
        tableData={data}
        pagination
        header
        title="Sequence Information"
        restore
        exportFile={cardCollapsed === "genome"}
        urlList={[
          { downloadUrl: sequrl, downloadName: "qc_sequences.fasta" },
          { downloadUrl: qcresultsurl, downloadName: "qc_results.tsv" },
        ]}

      />
    </>
  );
};

export default SequenceTable;
