import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  createArtificialCovidGenomeData,
  getMutationNameSuggestions,
} from "../../api/mutations";

export const getMutationNameSuggestionsAsync = createAsyncThunk(
  "mutations/getMutationNameSuggestions",
  async (text: string) => {
    const response = await getMutationNameSuggestions(text);
    return response.data;
  }
);

export const createArtificialCovidGenomeDataAsync = createAsyncThunk(
  "mutations/createArificialCovidGenomeData",
  async (data: Array<Array<string>>, thunkApi) => {
    try {
      const response = await createArtificialCovidGenomeData(data);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
