import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { showNotification } from "../../shared/Notification";
import {
  createArtificialCovidGenomeDataAsync,
  getMutationNameSuggestionsAsync,
} from "../asyncThunks/mutationsThunks";
import { RootState } from "../store";

export interface MutationsState {
  status: "idle" | "loading" | "failed";
  mutationNameSuggestions: string[];
}

const initialState: MutationsState = {
  status: "idle",
  mutationNameSuggestions: [],
};

export const mutationsSlice = createSlice({
  name: "mutations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Get Mutation Name Suggestions
      .addCase(getMutationNameSuggestionsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMutationNameSuggestionsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.mutationNameSuggestions = JSON.parse(action.payload).map(
          (element: string) => {
            return { value: element, label: element };
          }
        );
      })
      .addCase(getMutationNameSuggestionsAsync.rejected, (state) => {
        state.status = "failed";
        showNotification({
          type: "danger",
          message: "Could not get suggestions",
        });
      })

      .addCase(
        createArtificialCovidGenomeDataAsync.fulfilled,
        (state, action) => {
          state.status = "idle";
          console.log(action.payload);
        }
      )
      .addCase(
        createArtificialCovidGenomeDataAsync.rejected,
        (state, action: any) => {
          state.status = "failed";
          showNotification({
            type: "danger",
            message: action.payload.detail,
          });
        }
      );
  },
});

export const selectLoading = (state: RootState) => state.mutations.status;

export default mutationsSlice.reducer;
