import React from "react";
import { useSelector } from "react-redux";
import { Column } from "react-table";
import ReactTooltip from "react-tooltip";
import { RootState } from "../../../redux/store";
import BasicTable from "../../../shared/table/BasicTable";
import { ISequenceInfoTable } from "../../../types/interfaces";

const GenomeCheck = () => {
  const checkData = useSelector(
    (state: RootState) => state.genome.qcCheckSequenceData
  );

  const headers: Column<ISequenceInfoTable>[] = [
    {
      Header: "Sequence Length",
      accessor: "sequence_length",
      Cell: (row) => {
        return (
          <span
            style={{
              color: Object.values(row.row.original).includes("Failed")
                ? "#A0080E"
                : "#157016",
            }}
          >
            {row.value}
          </span>
        );
      },
    },
    {
      Header: "Fasta Header",
      accessor: "user_fasta_header",
      Cell: (row) => {
        return (
          <span
            style={{
              color: Object.values(row.row.original).includes("Failed")
                ? "#A0080E"
                : "#157016",
            }}
            data-tip={row.value}
          >
            {row.value.slice(0, 15)}
          </span>
        );
      },
    },
    {
      Header: "Length",
      accessor: "check_sequence_length",
      Cell: (row) => {
        return (
          <span
            style={{
              color: Object.values(row.row.original).includes("Failed")
                ? "#A0080E"
                : "#157016",
            }}
          >
            {row.value}
          </span>
        );
      },
    },
    {
      Header: "ACTG Proportion",
      accessor: "check_ACTG_proportion",
      Cell: (row) => {
        return (
          <span
            style={{
              color: Object.values(row.row.original).includes("Failed")
                ? "#A0080E"
                : "#157016",
            }}
          >
            {row.value}
          </span>
        );
      },
    },
    {
      Header: "GC Content",
      accessor: "check_GC_content",
      Cell: (row) => {
        return (
          <span
            style={{
              color: Object.values(row.row.original).includes("Failed")
                ? "#A0080E"
                : "#157016",
            }}
          >
            {row.value}
          </span>
        );
      },
    },
    {
      Header: "Congruence",
      accessor: "check_congruence",
      Cell: (row) => {
        return (
          <span
            style={{
              color: Object.values(row.row.original).includes("Failed")
                ? "#A0080E"
                : "#157016",
            }}
          >
            {row.value}
          </span>
        );
      },
    },
    {
      Header: "Summary",
      accessor: "qc_summary",
      Cell: (row) => {
        return (
          <span
            style={{
              color: Object.values(row.row.original).includes("Failed")
                ? "#A0080E"
                : "#157016",
            }}
          >
            {row.value}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <div className="genome-check__container">
        <BasicTable
          tableHeaders={headers}
          tableData={JSON.parse(checkData)}
          pagination
          qcCheckTable
        />
      </div>
      <ReactTooltip
        place="top"
        type="dark"
        effect="solid"
        multiline={true}
        className="table__tooltip"
      />
    </>
  );
};

export default GenomeCheck;
