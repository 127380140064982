import React from "react";
import Image from "../assets/img/cardImage.jpg";
import { BsLayoutTextWindow } from "react-icons/bs";

interface HeaderProps {
  headerImage: string;
}

const Header = () => {
  return (
    <div className="research__header">
      {/* <div className="header__image">
        <img src={Image} alt="Header Image" />
      </div> */}
      <div className="header__content-conteiner">
        <div className="header__content">
          {/* <div className="header__meta-container">
            <div className="image">
              <BsLayoutTextWindow />
            </div>
            <div className="page-title">Research</div>
          </div> */}
          <h2>How does CovidOutcome2 work?</h2>
        </div>
      </div>
    </div>
  );
};

export default Header;
